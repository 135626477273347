.eon-clear {
    padding: 0px;
    margin: 0px;
}

.eon-vertical-center {
    display: flex;
    align-items: center;
}

.eon-neuo-card {
    margin: 30px 30px 30px 30px;
    padding: 10px;
    width: calc(100% - 60px);
    border-radius: 20px;
    background-color: var(--eon-bg);
    // Intensity 0.7
    box-shadow: 5px 5px 10px var(--eon-shadow-07-low), 
                -5px -5px 10px var(--eon-shadow-07-top);
}

.eon-neuo-card-header {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    font-variant: small-caps;
    padding-left: 15px;
    color: rgba(0,0,0,.7);
    font-weight: 300;
    letter-spacing: 2px;
}

.eon-neuo-card-hero {
    margin: 0px 0px 10px 0px;
    padding: 10px;
    padding-left: 25px;
    width: calc(100% + 20px);
    transform: translate3d(-10px, -10px, 0);
    border-radius: 10px;
    font-size: 20px;
    letter-spacing: 2px;
    font-variant: small-caps;
    color: rgba(0,0,0,.7);
    background-color: var(--eon-bg);
    box-shadow: 
        5px 5px 10px var(--eon-shadow-07-low), 
        -5px -5px 10px var(--eon-shadow-07-top),    
        inset 3px 3px 6px var(--eon-shadow-07-low), 
        inset -3px -3px 6px var(--eon-shadow-07-top);
}

.eon-neuo-sticker {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #fcfcfc;
    padding: 15px;
    box-shadow: 1px 1px 2px rgba(0,0,0,.03);
    transform: rotate(-0.5deg);
    color: rgba(0,0,0,.4);
    font-size: 12px;
}

.eon-neuo-box {
    padding: 7px;
    width: calc(100% - 60px);
    border-radius: 10px;
    //background-color: var(--eon-bg);
    /*
    box-shadow: 
        5px 5px 7px var(--eon-shadow-04-low), 
        -5px -5px 7px var(--eon-shadow-04-top);
    */
}

.eon-neuo-box-ultrasoft {
    box-shadow: 8px 8px 16px var(--eon-shadow-03-low),
        -8px -8px 16px var(--eon-shadow-03-top);
}

.eon-neuo-lasercut-outer {
    border-radius: 10px;
    padding: 2px;
    padding-top: 0px;
    padding-bottom: 0px;
    box-shadow: 1px 1px 2px var(--eon-shadow-04-low), 
                -1px -1px 2px var(--eon-shadow-04-top);    
}

.eon-neuo-lasercut {
    border-radius: 10px;
    padding: 1px;
    //background-color: rgba(0,0,0,.05);
    background-color: var(--eon-lasercut);
}
.eon-no-move .eon-neuo-lasercut:active {
    animation: none;
    background-color: var(--eon-bg);
}
.eon-no-move .eon-neuo-lasercut.frosted:active {
    background-color: rgba(0,0,0,0);
}

.eon-neuo-lasercut.variant-standalone {
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.radiance-positive {
    /*
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: animation-radiance-positive;
    */
    //@function: rgba(98, 176, 163, 0.7);
}
@keyframes animation-radiance-positive {
    0% { background-color: rgba(0, 0, 0, .05); }
    25% { background-color: rgba(0, 0, 0, .05); }
    50% { background-color: rgba(98, 176, 163, 0.7); }
    75% { background-color: rgba(0, 0, 0, .05); }    
    100% { background-color: rgba(0, 0, 0, .05); }
}
.raidance-warning {
    /*
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: animation-radiance-warning;
    */
}
@keyframes animation-radiance-warning {
    0% { background-color: rgba(0, 0, 0, .05); }
    25% { background-color: rgba(0, 0, 0, .05); }
    50% { background-color: rgba(242, 165, 129, 0.7); }
    75% { background-color: rgba(0, 0, 0, .05); }    
    100% { background-color: rgba(0, 0, 0, .05); }
}
.raidance-danger {
    /*
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: animation-radiance-warning;
    */
}
@keyframes animation-radiance-danger {
    0% { background-color: rgba(0, 0, 0, .05); }
    25% { background-color: rgba(0, 0, 0, .05); }
    50% { background-color: rgba(187, 143, 158, 0.7); }
    75% { background-color: rgba(0, 0, 0, .05); }    
    100% { background-color: rgba(0, 0, 0, .05); }
}

.eon-neuo-box-button {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: var(--eon-bg);
    padding: 5px;
    //transition: .3s;
    box-shadow: var(--eon-boxbutton-shadow);
    color: var(--eon-contrast-text);
}
.eon-no-move .eon-neuo-box-button:active {
    box-shadow: 
        inset 5px 5px 10px var(--eon-shadow-07-low), 
        inset -5px -5px 10px var(--eon-shadow-07-top);
}
.eon-move .eon-neuo-box-button:active {
    box-shadow: inset 3px 3px 3px var(--eon-shadow-inset-glance);
}

.eon-neuo-box-button.frosted {
    box-shadow: inset 3px 3px 3px rgba(247, 246, 246, 0.1);
    background-color: rgba(247, 246, 246, 0.07);
    color: white;
}
.eon-no-move .eon-neuo-box-button.frosted:active {
    box-shadow: inset 3px 3px 3px rgba(8, 8, 9, 0.4);
    //background-color: rgba(247, 246, 246, 0.07);
    color: white;
    background-color: none;
}
.eon-move .eon-neuo-box-button.frosted:active {
    box-shadow: inset 3px 3px 3px rgba(247, 246, 246, 0.1);
    //background-color: rgba(247, 246, 246, 0.07);
    color: white;
    background-color: none;
}

.eon-neuo-box-button.variant-standalone {
    font-size: 14px;
    font-style: normal;
    font-variant: small-caps;
    padding: 10px;
    font-weight: 600;
    text-align: center;
}

.eon-neuo-box-button.variant-advert {
    background-color: #E0999D;
    box-shadow: inset 3px 3px 3px #e6adb0;
    color: white;
}
.eon-neuo-box-button.variant-shop4 {
    background-color: #2F4858;
    box-shadow: inset 3px 3px 3px #334e60;
    color: white;
}

.eon-neuo-box-button.variant-live5 {
    background-color: #2F4858;
    box-shadow: inset 3px 3px 3px #345062;
    color: white;    
}

.eon-neuo-box-button.variant-purchase {
    font-size: 20px;
    text-align: center;
    padding: 10px;
    background-color: #518070;
    box-shadow: var(--eon-boxbutton-shadow), inset 2px 2px 5px rgba(255,255,255,0.3);
    font-weight: bold;
    font-variant: small-caps;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
}
.eon-neuo-box-button.variant-membership {

}

.eon-neuo-box-button.small {
    //border-radius: 7px;
}
/*
.eon-move .eon-neuo-box-button--frosted:active {
    //box-shadow: inset 3px 3px 3px rgba(255,255,255,.3);
    box-shadow: none;
    background-color: rgba(247, 246, 246, 0.07);
    color: white;
}
.eon-no-move .eon-neuo-box-button--frosted:active {
    //box-shadow: inset 3px 3px 3px rgba(255,255,255,.3);
    box-shadow: none;
    background-color: rgba(247, 246, 246, 0.07);
    color: white;
}
*/


.eon-neuo-box-button.small {
    padding: 0px;
    font-size: 8px;
}

.eon-neuo-toggle {
    border-radius: 30px;
    background-color: var(--eon-bg);
    // Intensity .05
    box-shadow:
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top),
        inset 5px 5px 7px var(--eon-shadow-10-low), 
        //inset -5px -5px 7px #f6f5f5;
        inset -5px -5px 7px var(--eon-shadow-03-top);
    font-size: 16px;
    padding: 5px;
    font-weight: 800;
    text-align: center;
    border: 3px solid var(--eon-bg);
    color: white;
    width: 50px;
    height: 30px;
    margin: auto;
    padding: 2px;
    transition: .2s;
}
.eon-neuo-toggle-checked {
    background-color: #518070;
    // Intensity .15
    box-shadow:
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top),
        inset 5px 5px 3px #53968b, 
        inset -5px -5px 3px #71cabb;    
}
.eon-neuo-toggle-inner {
    width: 20px;
    height: 20px;
    background-color: var(--eon-bg);
    border-radius: 30px;
    transition: .2s;
}
.eon-neuo-toggle-inner-checked {
    transform: translateX(20px);
}

/* ################################################################################################################ */
/* ################################################################################################################ */
/* ################################################################################################################ */

.eon-neuo-inset-badge {
    border-radius: 30px;
    box-shadow: 2px 2px 4px var(--eon-shadow-10-low), 
                -2px -2px 4px #ffffff;
    font-size: 16px;
    padding: 5px;
    font-weight: 800;
    text-align: center;
    border: 3px solid var(--eon-bg);
    color: white;
}
.frosted .eon-neuo-inset-badge {
    border: 3px solid rgba(247, 246, 246, 0.3);
    box-shadow: 
        1px 1px 2px rgba(8,8,9,.27), 
        -1px -1px 2px rgba(247,246,246,.17);
}

.eon-neuo-inset-badge.eon-neuo-positive {
    background-color: #518070;
    // Intensity .15
    box-shadow:
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top),
        inset 5px 5px 3px #406659,
        inset -5px -5px 3px #73998C;
}
.frosted .eon-neuo-inset-badge.eon-neuo-positive {
    background-color: rgba(98, 176, 163, .5);
    border: none;
    box-shadow:
        inset 5px 5px 3px rgba(0,0,0, .17), 
        inset -5px -5px 3px rgba(255,255,255, .07);
}

.eon-neuo-inset-badge.eon-neuo-warning {
    background-color: #85A69A;
    // Intensity .15
    box-shadow:
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top),
        inset 5px 5px 3px #5D746B,
        inset -5px -5px 3px #A9C0B8;
}
.frosted .eon-neuo-inset-badge.eon-neuo-warning {
    background-color: rgba(242, 165, 129, .5);
    border: none;
    box-shadow:
        inset 5px 5px 3px rgba(0,0,0, .17), 
        inset -5px -5px 3px rgba(255,255,255, .07);    
}

.eon-neuo-inset-badge.eon-neuo-danger { 
    background-color: #F88F6F;
    // Intensity .15
    box-shadow:
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top),
        inset 5px 5px 3px #AD644D,
        inset -5px -5px 3px #FAB09A;
}
.frosted .eon-neuo-inset-badge.eon-neuo-warning {
    background-color: rgba(187, 143, 158, .5);
    border: none;
    box-shadow:
        inset 5px 5px 3px rgba(0,0,0, .17), 
        inset -5px -5px 3px rgba(255,255,255, .07);
}

.eon-neuo-inset-badge.eon-neuo-default { 
    color: rgba(0,0,0,.5);
    background-color: var(--eon-bg);
    // Intensity .05
    box-shadow:
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top),
        inset 5px 5px 7px var(--eon-shadow-10-low), 
        inset -5px -5px 7px #f6f5f5;
}
.frosted .eon-neuo-inset-badge.eon-neuo-default { 
    color: rgba(0,0,0,.1);
    border: none;
    background-color: rgba(247,246,246,.1);
    box-shadow:
        inset 5px 5px 3px rgba(0,0,0, .17), 
        inset -5px -5px 3px rgba(255,255,255, .07);
}

.eon-neuo-inset-badge.eon-neuo-passive { 
    background-color: var(--eon-bg);
    // Intensity .05
    box-shadow:
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px rgba(255,255,255,.05),
        inset 2px 2px 5px var(--eon-shadow-10-low), 
        inset -2px -2px 5px var(--eon-inset-bottom);
}
.frosted .eon-neuo-inset-badge.eon-neuo-passive { 
    color: rgba(0,0,0,.1);
    border: none;
    background-color: rgba(247,246,246,.1);
    box-shadow:
        inset 5px 5px 3px rgba(0,0,0, .12), 
        inset -5px -5px 3px rgba(255,255,255, .03);
}

/* ################################################################################################################ */
/* ################################################################################################################ */
/* ################################################################################################################ */

.eon-neuo-handle {
    height: 10px;
    width: 50px;
    margin: auto;
    margin-top: 15px;
    border-radius: 30px;
    //background: linear-gradient(145deg, #ffffff, #dddfdf);
    box-shadow: 1px 1px 2px var(--eon-shadow-04-low), 
                -1px -1px 2px var(--eon-shadow-04-top);
}
.eon-neuo-handle.advert {
    box-shadow:  
        1px 1px 2px #f2f16e, 
        -1px -1px 2px #ffff74;
}
.eon-neuo-handle.variant-shop1 { box-shadow: 1px 1px 2px #74a19d, -1px -1px 2px #8cc1bd; }
.eon-neuo-handle.variant-shop2 { box-shadow: 1px 1px 2px #568085, -1px -1px 2px #689a9f; }
.eon-neuo-handle.variant-shop3 { box-shadow: 1px 1px 2px #3f606b, -1px -1px 2px #4b7481; }
.eon-neuo-handle.variant-shop4 { box-shadow: 1px 1px 2px #2b4250, -1px -1px 2px #334e60; }
.eon-neuo-handle.variant-advert { box-shadow: 1px 1px 2px #cc8b8f, -1px -1px 2px #f4a7ab; }
.eon-neuo-handle.variant-live1 { box-shadow: 1px 1px 2px #a3c9b5, -1px -1px 2px #b5dfc9; }
.eon-neuo-handle.variant-live2 { box-shadow: 1px 1px 2px #7ca8a0, -1px -1px 2px #89bab0; }
.eon-neuo-handle.variant-live3 { box-shadow: 1px 1px 2px #5b8689, -1px -1px 2px #659497; }
.eon-neuo-handle.variant-live4 { box-shadow: 1px 1px 2px #41656f, -1px -1px 2px #476f7b; }
.eon-neuo-handle.variant-live5 { box-shadow: 1px 1px 2px #2a404e, -1px -1px 2px #345062; }

.eon-neuo-list {
    margin-top: 5px;
    margin-bottom: 30px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;
    width: calc(100% - 60px);
    border-radius: 10px;
    background-color: var(--eon-bg);
    box-shadow: 5px 5px 10px var(--eon-shadow-07-low), 
                -5px -5px 10px var(--eon-shadow-04-top);
}
.eon-neuo-list.margin30 {
    margin-left: 30px;
    margin-right: 30px;
}
.eon-neuo-list.flat {
    box-shadow: none;
}

.eon-neuo-list-button {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    background-color: var(--eon-bg);
    border-top: 1px solid rgba(0,0,0,0.05);
    border-radius: 10px;
    box-shadow: inset 2px 1px 3px var(--eon-shadow-inset-glance);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.color .eon-neuo-list-button {
    background-color: var(--eon-button-bg);
}
.eon-neuo-list-button:last-of-type {
    border-bottom: 1px solid rgba(0,0,0,.05);
}
.eon-no-move .eon-neuo-list-button:last-of-type:active {
    border-bottom: 1px solid var(--eon-bg);
}
.eon-no-move .eon-neuo-list-button:active {
    box-shadow: 
        inset 5px 5px 10px var(--eon-shadow-07-low), 
        inset -5px -5px 10px var(--eon-button-shadow-inset-bottom);
}
.eon-move .eon-neuo-list-button:active {
    box-shadow: inset 2px 1px 3px var(--eon-shadow-inset-glance);
}
.eon-no-move .eon-neuo-list-button:active + div {
    border-top: 1px solid var(--eon-bg);
}

.eon-neuo-list-button-inner {
    margin-left: 10px;
    margin-right: 10px;
}

.eon-neuo-list-button-icon {
    height: 30px;
    width: 40px;
    align-self: center;
    flex-shrink: 0;
    font-size: 30px;
    line-height: 30px;
    color: var(--eon-contrast-text-less);
}
.eon-neuo-list-button-icon.small {
    font-size: 16px;
    line-height: 20px;
    padding: 5px;
    text-align: center;
    height: 20px;
    width: 100%;
}

.eon-neuo-list-button-avatar {
    width: 55px;
    height: 55px;
    align-self: center;
    flex-shrink: 0;
    border-radius: 100%;
    border: 3px solid var(--eon-bg);
    margin-right: 5px;
    box-shadow: 1px 1px 2px var(--eon-shadow-04-low), 
                -1px -1px 2px var(--eon-shadow-04-top);
}

.eon-neuo-list-button-logo {
    width: 40px;
    height: 40px;
    align-self: center;
    flex-shrink: 0;
}

.eon-neuo-list-button-title {
    width: 100%;
    height: 30px;
    align-self: center;
    flex-grow: 1;
    overflow: hidden;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    //color: var(--eon-contrast-100);
    color: var(--eon-contrast-text);
}
.eon-neuo-list-button-title.small { 
    font-size: 12px;
    line-height: 14px;
    padding: 5px;
    border-radius: 20px;
}
.eon-neuo-list-button-title.autoHeight { height: auto; hyphens: auto; }
.eon-neuo-list-button-title.left { text-align: left; }
.eon-neuo-list-button-title.smallCaps { font-variant: small-caps; }

.eon-neuo-list-button-chevron {
    height: 30px;
    width: 30px;
    align-self: center;
    flex-shrink: 0;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    border-radius: 100%;
    //color: rgba(0,0,0,.5);
    color: var(--eon-contrast-text);
    box-shadow: 
        2px 2px 4px var(--eon-shadow-14-low), 
        -2px -2px 4px var(--eon-shadow-14-top);
}

.eon__neuo__iap {
    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 10px;
    background-color: #81D399;
    border-radius: 20px;
    background-size: cover;
    background-position: bottom right;
}

.eon__neuo__iap__heading {
    font-variant: small-caps;
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: 15px;
    color: var(--eon-contrast-text);
}

.eon__neuo__iap__subheading {
    font-variant: small-caps;
    font-size: 10px;
    font-weight: bold;
    color: var(--eon-contrast-text-less);
}

.eon__neuo__product {
    //margin: 30px 30px 30px 30px;
    padding: 5px;
    border-radius: 10px;
    background-color: var(--eon-bg);
    // Intensity 0.7
    box-shadow: 2px 2px 4px var(--eon-shadow-07-low), 
                -2px -2px 4px var(--eon-shadow-07-top);
}

.eon__neuo__product__heading {
    font-variant: small-caps;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--eon-contrast-text);
}

.eon__neuo__product__text {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--eon-contrast-text);
}

.eon__neuo__product__image {
    height: 100px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    background-color: coral;
    box-shadow: inset 2px 2px 4px rgba(0,0,0,.7), 
                inset -2px -2px 4px rgba(0,0,0,.3);
}

.eon__neuo__item {
    //margin: 30px 30px 30px 30px;
    margin: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - 60px);
    border-radius: 10px;
    background-color: var(--eon-bg);
    // Intensity 0.7
    /*
    box-shadow: 3px 3px 5px var(--eon-shadow-07-low), 
                -3px -3px 5px var(--eon-shadow-07-top);
    */
}

.eon__neuo__item__heading {
    font-variant: small-caps;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--eon-contrast-text);
}

.eon__neuo__item__row {
    margin-left: 10px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}
.eon__neuo__item__row:last-of-type {
    margin-bottom: 10px;
}

.eon__neuo__item__icon {
    height: 30px;
    width: 40px;
    align-self: center;
    flex-shrink: 0;
    font-size: 30px;
    line-height: 30px;
    color: var(--eon-contrast-text);
}

.eon__neuo__item__middle {
    padding-left: 10px;
    padding-right: 5px;
    flex-grow: 1;
    flex-shrink: 1;
    color: var(--eon-contrast-text);
}

.eon__neuo__item__middle__firstLine {
    overflow: hidden;
    hyphens: auto;
    color: var(--eon-contrast-text);
}

.eon__neuo__item__middle__secondLine {
    font-size: 10px;
    //color: rgba(0,0,0,.5);
    color: var(--eon-contrast-text-less);
    hyphens: auto;
}

.eon__neuo__item__button {
    width: 70px;
    flex-shrink: 0;
}

.eon__neuo__item__toggle {
    width: 70px;
    flex-shrink: 0;
}



.eon-item-icon {
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    font-size: 25px;
}

.eon-item-icon-raised {
    height: 40px;
    width: 40px;
    margin: auto;
    font-size: 20px;
    border-radius: 10px;
    border: 1px solid var(--eon-bg);
    box-shadow: 
        5px 5px 10px var(--eon-shadow-07-low), 
        -5px -5px 10px #ffffff,
        inset 5px 5px 10px var(--eon-shadow-07-low), 
        inset -5px -5px 10px #ffffff;    
}

.eon-item-avatar {
    height: 40px;
    width: 40px;
    margin: auto;
    font-size: 20px;
    border-radius: 100%;
    border: 1px solid var(--eon-bg);
    box-shadow: 
        5px 5px 10px var(--eon-shadow-07-low), 
        -5px -5px 10px #ffffff,
        inset 5px 5px 10px var(--eon-shadow-07-low), 
        inset -5px -5px 10px #ffffff;    
}

.eon-item-twoliner {
    min-height: 40px;
}
.eon-item-twoliner-first {
    font-size: 16px;
}
.eon-item-twoliner-second {
    font-size: 10px;
    color: rgba(0,0,0,.7);
}


.eon-item-title {
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    font-size: 16px;   
    overflow: hidden;
    hyphens: auto;
}

.eon-item-subtitle {
    font-size: 10px;
    color: rgba(0,0,0,.7);
    margin: 0px;
    padding: 0px;
    transform: translateY(-10px);
}

.eon-item-input {
}

.eon-item-text {
    font-size: 16px;
    color: rgba(0,0,0,.7);
}

.eon-neuo-pic-avatar {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 3px solid var(--eon-bg);
    box-shadow: 1px 1px 2px var(--eon-shadow-04-low), 
                -1px -1px 2px var(--eon-shadow-04-top);
}

.eon-neuo-pic-mockup {
    margin-left: 0px;
    display: inline-block;
    width: 80px;
    height: 80px;
    padding: 10px;
    border-radius: 10px;
    border: 3px solid var(--eon-bg);
    //background-color: white;
    box-shadow: 
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top),
        inset 1px 1px 2px var(--eon-shadow-04-low), 
        inset -1px -1px 2px var(--eon-shadow-04-top);
}

.eon-neuo-image {
    margin-left: 0px;
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    border: 3px solid var(--eon-bg);
    box-shadow: 
        2px 2px 4px var(--eon-shadow-04-low), 
        -2px -2px 4px var(--eon-shadow-04-top),
        inset 2px 2px 4px rgba(0,0,0,.07), 
        inset -2px -2px 4px rgba(255,255,255,.5);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
}

.eon-container-progress {
    border: 3px solid var(--eon-bg);
    border-radius: 10px;   
    margin-bottom: 10px;
    box-shadow: 
        5px 5px 7px var(--eon-shadow-04-low), 
        -5px -5px 7px var(--eon-shadow-04-top),
        inset 5px 5px 7px var(--eon-shadow-04-low), 
        inset -5px -5px 7px var(--eon-shadow-04-top),        
}

.eon-progress {
    width: 50%;
    height: 30px;
    border-radius: 10px;
    white-space: nowrap;
    font-size: 14px;
    padding-top: 6px;
    padding-left: 10px;
    font-weight: 600;
    color: #4a4949;
    background-image: 
      repeating-linear-gradient(
        -45deg,
        var(--eon-bg),
        var(--eon-bg) 11px,
        #518070 10px,
        #518070 20px /* determines size */
      );
    background-size: 28px 28px;
    animation: move .5s linear infinite;
}

@keyframes omove {
    0% { background-position: 0 0; }
    100% { background-position: 28px 0; }
}

.eon-neuo-text {
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0,0,0,.7);
    text-align: left;
}

.eon-neuo-footer-outer {
    padding-top: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transform: translateY(-20px);
    background-color: var(--eon-neuo-footer-outer-background);
    height: 90px;
    z-index: 1000;
    animation: fadein .4s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.eon-neuo-footer-barrier-outer {
    padding: 10px;
    padding-top: 5px;
    width: calc(100% - 10px);
    margin: auto;
    border-radius: 20px;
    font-size: 30px;
    color: var(--eon-bg-70);
    height: 50px;
    margin: auto;
    background-color: var(--eon-bg);
    box-shadow: 
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top),
        inset 1px 1px 2px var(--eon-shadow-04-low), 
        inset -1px -1px 2px var(--eon-shadow-04-top);        
    border: 4px solid var(--eon-bg);
}

.eon-neuo-footer-barrier-inner {
    width: calc(100% + 50px);
    height: calc(100% + 30px);
    //background-image: var(--eon-shadow-footer-barrier-inner-background);
    transform: translate3d(-25px, 0px, 0);
}

.eon-neuo-footer-inner {
    width: 100%;
    height: 100%;
    transform: translate3d(25px, 0,0);
    width: calc(100% - 50px);
    height: calc(100% - 30px);
}

.eon-neuo-header-outer {
    padding-top: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    //transform: translateY(-20px);
    background-color: rgba(247, 246, 246, 0.7);
    height: 70px;
}

.eon-neuo-header-barrier-outer {
    padding: 10px;
    padding-top: 5px;
    width: calc(100% - 10px);
    margin: auto;
    border-radius: 20px;
    font-size: 30px;
    color: rgba(0,0,0,.7);
    height: 40px;
    margin: auto;
    background-color: var(--eon-bg);
    box-shadow: 
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top),
        inset 1px 1px 2px var(--eon-shadow-04-low), 
        inset -1px -1px 2px var(--eon-shadow-04-top);        
    border: 4px solid var(--eon-bg);
}

.eon-neuo-header-barrier-inner {
    width: calc(100% + 50px);
    height: calc(100% + 30px);
    background-image: linear-gradient(to top, rgba(247, 246, 246, 0), rgba(247, 246, 246, 1), rgba(247, 246, 246, 1));
    transform: translate3d(-25px, -25px, 0);
}

.eon-neuo-header-inner {
    width: 100%;
    height: 100%;
    transform: translate3d(25px, 0,0);
    width: calc(100% - 50px);
    height: calc(100% - 30px);
}

/*
#####################################################################################################################
#####################################################################################################################
#####################################################################################################################
*/

.cardboard {
    margin: 30px;
    padding: 10px;
    background-size: cover;
    background-color: white;
    border-radius: 10px;
    position: relative;
    top: 0px; 
    box-shadow: 3px 3px 3px rgba(0,0,0,.05);
    color: rgba(0,0,0,.7);
}

.cardboard:after {
  content: "";
  display: block;
  position: absolute;
  border: 50px solid transparent;
  border-bottom: 50px solid var(--eon-bg);
  bottom: -60px;
  right: -65px;
  box-shadow: 0px 7px 6px -9px rgba(0,0,0,.5);
  transform: rotate(135deg);
}

.cardboard:before {
  content: "";
  display: block;
  position: absolute;
  border: 50px solid transparent;
  border-top: 50px solid var(--eon-bg);
  top: -60px;
  left: -65px;
  box-shadow: 0px -7px 6px -9px rgba(0,0,0,.5);
  transform: rotate(135deg);
}

.cardboard-heading {
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
    font-variant: small-caps;
    margin-bottom: 15px;
    padding-left: 25px;
}

.cardboard-subheading {
    font-size: 10px;
    font-weight: 600;
    font-style: italic;
    font-variant: small-caps;
    padding-left: 25px;
    color: rgba(0,0,0,.5);
}

.cardboard-text {
    padding-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0,0,0,.7);
}

.cardboard-subtext {
    font-size: 10px;
    font-weight: 600;
    font-style: italic;
    font-variant: small-caps;
    padding-left: 5px;
    color: rgba(0,0,0,.5);
}

.cardboard2 {
    margin: 0px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    top: 30px; 
    box-shadow: 3px 3px 3px rgba(0,0,0,.05);
    //color: rgba(0,0,0,.7);


    font-size: 14px;
    color: rgba(8, 9, 9, .6);
    font-weight: 500;
    line-height: 21px;
}

.cardboard2::before {
    display: block;
    content: "";
    position: absolute;
    top: -20px;
    left: calc(50% - 25px);
    width: 50px;
    height: 30px;
    background-color: var(--eon-bg);
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: 0px 2px 2px #e6e6e6;
}

.cardboard2::after {
    display: block;
    content: "";
    position: absolute;
    bottom: -20px;
    left: calc(50% - 25px);
    width: 50px;
    height: 30px;
    background-color: var(--eon-bg);
    border-radius: 10px;
    box-shadow: 0px -1px 1px #e6e6e6;
}

.button-outer {
    border-radius: 30px;
    box-shadow: 2px 2px 4px var(--eon-shadow-10-low), 
                -2px -2px 4px #ffffff;
    font-size: 14px;
    text-align: center;
    padding: 3px;
    border: 3px solid var(--eon-bg);
    color: rgba(0,0,0,.7);
}

.button-lasercut {
    background-color: var(--eon-bg);
    padding: 1px;
    // Intensity .05
    box-shadow:
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top),
        inset 5px 5px 7px var(--eon-shadow-10-low), 
        inset -5px -5px 7px var(--eon-shadow-10-top);
}

.button-inner {
    background-color: var(--eon-bg);
    border-radius: 10px;
    padding: 2px;
    box-shadow: inset 2px 1px 3px var(--eon-shadow-inset-glance);
}
.button-inner:active {
    color: #518070;
    box-shadow: 
        inset 5px 5px 10px var(--eon-shadow-07-low), 
        inset -5px -5px 10px #ffffff;
}

.eon-neuo-test1 {
    border-radius: 20px;
    margin: 30px;
    width: 100px;
    border: 5px solid var(--eon-bg);
    box-shadow: 1px 1px 2px var(--eon-shadow-04-low), 
                -1px -1px 2px var(--eon-shadow-04-top);            
}
.eon-neuo-test2 {
    width: 100%;
    height: 100%;
    border: 5px solid rgba(0,0,0,.4);
}
.eon-neuo-test3 {
    width: 100%;
    height: 100%;
    background-image: url('https://avoidsociety.app/cover/square/pa1.jpg');
    background-position: center;
    background-size: cover;
}
.eon-neuo-test4 {
    width: 100%;
    height: 100px;
    background-image: url('https://avoidsociety.app/mockups/pa1-mockup.png');
    background-position: center;
    background-size: cover;
}
.eon-neuo-test5 {
    margin-left: 5px;
    width: 100%;
    font-variant: small-caps;
    font-weight: 800;
    font-size: 20px;
    text-shadow: 1px 1px 1px rgba(0,0,0,.4);
    color: rgba(255,255,255,1);
}

.eon-neuo-button-fat {
    position: relative;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    color: rgba(0,0,0,.5);
    border-radius: 111px;
    background: linear-gradient(145deg, #ffffff, #dedddd);
    box-shadow:  5px 5px 12px #dcdbdb, 
                 -5px -5px 12px #ffffff;
}
.eon-neuo-button-fat:active {
    color: #518070;
    text-shadow: -2px -2px 5px rgba(98, 176, 163,.5);
    background: linear-gradient(315deg, #ffffff, #dedddd);
    box-shadow:  -5px -5px 12px #dcdbdb, 
                 5px 5px 12px #ffffff;
}

.eon-neuo-button-fat__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.eon-neuo-button-fat__icon {
}
.eon-neuo-button-fat__title {
    font-variant: small-caps;
    font-size: 10px;
    font-weight: 600;
}

.eon__neuo__card {
    background-color: var(--eon-card-bg);
    border-radius: 10px;
    box-shadow: var(--eon-card-shadow);
    margin: 20px;
    padding: 15px;
}
.eon__neuo__card--invisible {
    margin: 20px;
    padding: 15px;
}
.eon__neuo__card--wide {
    margin-left: 0px;
    margin-right: 0px;
}
.eon__neuo__card--list {
    margin-left: 15px;
    margin-right: 15px;
}
.eon__neuo__card.--BLANK {
    background-color: var(--eon-bg);
    box-shadow: none;
}
.eon__neuo__card.--POSITIVE {
    background-color: #518070;
    color: rgba(255,255,255,.7);
    padding: 10px;
}

.eon__neuo__card.link:active {
    transform: scale(0.97,0.97);
    box-shadow: var(--eon-card-shadow-less);
}

.eon__neuo__card__heading {
    font-variant: small-caps;
    //font-size: 24px;
    //line-height: 32px;
    //font-weight: 800;
    font-weight: bold;
    margin-bottom: 15px;
    //color: var(--eon-contrast-text);

    color: var(--eon-contrast-text);
    font-variant: small-caps;
    font-size: 30px;    
}
.eon__neuo__card__heading.--thin {
    font-weight: normal;
}

.eon__neuo__card__subheading {
    font-variant: small-caps;
    font-size: 10px;
    line-height: 15px;
    //font-weight: bold;
    color: var(--eon-contrast-text-less);
    //color: var(--eon-contrast-text);
}
.--POSITIVE .eon__neuo__card__subheading {
    color: rgba(255,255,255,.7);
}

.eon__neuo__card__text {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    //font-weight: 500;
    line-height: 21px;
    hyphens: auto;
    //text-align: justify;
    white-space: initial;
    color: var(--eon-contrast-text);
}

.eon__neuo__card__subtext {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    hyphens: auto;
    //text-align: justify;
    color: var(--eon-contrast-text-less);
}
.--POSITIVE .eon__neuo__card__subtext {
    color: rgba(255,255,255,.7);    
}

.eon__neuo__card__button {
    padding: 10px;
    font-size: 12px;
    text-align: center;
    color: var(--eon-contrast-text);
    font-variant: small-caps;
    font-weight: 500;
    border-radius: 10px;
    border-style: solid;
    border-color: var(--eon-bg);
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 2px;
    border-bottom-width: 3px;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
}
.eon__neuo__card__button:active {
    //transform: scale(0.97,0.97);
    border-top-width: 2px;
    border-left-width: 2px;
    border-right-width: 1px;
    border-bottom-width: 2px;    
}
.eon__neuo__card__button.--accent {
    color: black;
    border-color: #395E00;
}
.--BLANK .eon__neuo__card__button {
    border-color: var(--eon-separation-line);
}
.eon__neuo__card__button.--POSITIVE {
    border-color: #38594E;
    margin-top: 0px;
}
.eon__neuo__card__button.--NORMAL {
    border-color: #030303;
}

.eon__neuo__card__progress__outer {
    font-size: 12px;
    text-align: center;
    color: var(--eon-contrast-text);
    font-variant: small-caps;
    font-weight: 500;
    border-radius: 10px;
    border-style: solid;
    border-color: var(--eon-bg);
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 2px;
    border-bottom-width: 3px;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;    
    overflow: hidden;
}
.eon__neuo__card__progress__outer.--POSITIVE {
    border-color: #38594E;
    color: rgba(255,255,255,1);
}
.eon__neuo__card__progress__inner {
    text-align: left;
    padding-left: 10px;
    white-space: nowrap;
    font-weight: 700;
    overflow: visible;
    height: 100%;
    min-height: 20px;
    width: 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-top: 2px;
    background-color: #F88F6F;
    box-shadow: 
        inset 2px 2px 5px #FAB09A,
        inset -2px -2px 5px #AD644D;
}

.eon__neuo__card__backdrop {
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 15px;
}

.eon__neuo__card.product {
    padding: 0px;
    overflow: hidden;
}

.eon__neuo__card.variant-advert {
    background-color: #FDA792;
}
.eon__neuo__card.variant-advert-product {
    background-image: linear-gradient(to right bottom, #fda792, #fda792, #fda792, #fda792, #fda792, #fba693, #f9a494, #f7a395, #f2a097, #ec9e9a, #e69b9c, #e0999d);
}
.eon__neuo__card.variant-shop1 {
    background-image: linear-gradient(to right bottom, #a7d5c5, #a7d5c5, #a7d5c5, #a7d5c5, #a7d5c5, #a4d2c3, #a0cfc1, #9dccbf, #95c5bb, #8ebeb6, #87b8b2, #80b1ad);
}
.eon__neuo__card.variant-shop2 {
    background-image: linear-gradient(to right bottom, #80b1ad, #80b1ad, #80b1ad, #80b1ad, #80b1ad, #7daeab, #7aaba8, #77a8a6, #71a1a1, #6b9a9c, #659497, #5f8d92);
}
.eon__neuo__card.variant-shop3 {
    background-image: linear-gradient(to right bottom, #5f8d92, #5f8d92, #5f8d92, #5f8d92, #5f8d92, #5d8a90, #5a878d, #58848b, #537d86, #4e7781, #49707b, #456a76);
}
.eon__neuo__card.variant-shop4 {
    background-image: linear-gradient(to right bottom, #456a76, #456a76, #456a76, #456a76, #456a76, #436774, #416471, #3f616f, #3b5b69, #375464, #334e5e, #2f4858);
}

.eon__neuo__card.variant-live1 {
    background-image: linear-gradient(to right bottom, #ddf8d7, #ddf8d7, #ddf8d7, #ddf8d7, #ddf8d7, #d9f5d5, #d4f2d3, #d0efd1, #c7e8cc, #bee2c8, #b5dbc3, #acd4bf);
}
.eon__neuo__card.variant-live2 {
    background-image: linear-gradient(to right bottom, #acd4bf, #acd4bf, #acd4bf, #acd4bf, #acd4bf, #a8d1bd, #a5cebb, #a1cbb9, #99c5b5, #91beb0, #89b8ac, #82b1a8);
}
.eon__neuo__card.variant-live3 {
    background-image: linear-gradient(to right bottom, #82b1a8, #82b1a8, #82b1a8, #82b1a8, #82b1a8, #7faea6, #7caba4, #79a8a2, #72a19e, #6c9a99, #669495, #608d90);
}
.eon__neuo__card.variant-live4 {
    background-image: linear-gradient(to right bottom, #608d90, #608d90, #608d90, #608d90, #608d90, #5d8a8e, #5b878b, #588489, #537d84, #4e777f, #49707a, #446a75);
}
.eon__neuo__card.variant-live5 {
    background-image: linear-gradient(to right bottom, #446a75, #446a75, #446a75, #446a75, #446a75, #426773, #406470, #3e616e, #3a5b69, #365463, #334e5e, #2f4858);
}

.eon__neuo__card.light-shadow {
    box-shadow: 2px 2px 3px rgba(0,0,0,.05);
}

.white-text .eon__neuo__card__heading {
    color: white;
}
.white-text .eon__neuo__card__subheading {
    color: white;
}
.white-text .eon__neuo__card__text {
    color: white;
}
.white-text .eon__neuo__card__subtext {
    color: white;
}

.eon__neuo__card__header {
    display: flex;
}

.eon__neuo__card__header__titles {
    flex-grow: 1;
    flex-shrink: 1;
    align-self: flex-start;
    padding-right: 10px;
}

.eon__neuo__card__header__avatar__outer {
    flex-basis: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
}

.eon__neuo__card__header__avatar__inner {
    width: 80px;
    height: 80px;
    overflow: visible;
}

.eon__neuo__card__header__avatar__pic {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    transform: translateX(-10px) translateY(-40px);
    border-radius: 100%;
    border: 3px solid var(--eon-bg);
    background-size: cover;
    background-position: center;
    box-shadow: 
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top);
}

.eon__neuo__advert {
    margin: 20px;
    background-color: var(--eon-bg);
    border-radius: 10px;
    padding: 5px;
    box-shadow: 
        2px 2px 4px var(--eon-shadow-03-low), 
        -2px -2px 4px var(--eon-shadow-03-top);
}

.eon__neuo__advert__inner {
    background-color: #F9F871;

    padding: 10px;
    border-radius: 10px;
}

.discovery__upper {
    display: flex;
    padding-left: 15px;
}

.discovery__outer {
    position: relative;
}

.discovery__inner {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}

.discovery__subheading__outer {
    width: 20px;
    height: 50px;
    align-self: flex-end;
}

.discovery__subheading__inner {
    height: 20px;
    width: 200px;
    text-align: left;
    transform: rotate(-90deg) translateX(-50px);
    transform-origin: top left;
    align-items: flex-end;
    font-size: 14px;
    font-variant: small-caps;
    font-weight: 600;
    color: rgba(247, 246, 246, 0.8);
}

.discovery__heading {
    margin-left: 0px;
    margin-right: 15px;
    color: white;
    font-size: 65px;
    font-weight: 900;
    font-variant: small-caps;
    text-align: left;
    line-height: 80%;
    align-self: flex-end;
}

.discovery__slides {
}

.discovery__slide {
    padding: 0px;
    padding-top: 15px;
    padding-bottom: 10px;
    width: calc(100% - 40px);
}
.discovery__slide:last-of-type {
    width: 100%;
    padding-right: 40px;
}
.discovery__slide.contact {
    display: block; 
    overflow-x: scroll;
}

.discovery__handle__top {
    width: 100px;
    height: 30px;
    margin: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(247, 246, 246, 0.07);
    backdrop-filter: blur(10px);
    box-shadow: 2px 2px 3px rgba(0,0,0,.3);
}

.discovery__handle__bottom {
    width: 100px;
    height: 50px;
    margin: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba(247, 246, 246, 0.07);
    backdrop-filter: blur(10px);
    box-shadow: 2px 2px 3px rgba(0,0,0,.3);    
}

.discovery__handle {
    top: 0px;
    width: 50px;
    height: 10px;
    margin: auto;
    margin-top: 5px;
    border-radius: 30px;
    background-color: rgba(247, 246, 246, 0.07); 
    box-shadow: 
        1px 1px 2px rgba(0,0,0,.2),
        -1px -1px 2px rgba(255,255,255,.2);   
}

.discovery__card__outer {
    width: 100%;
}
.discovery__card__inner {
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 10px;
    background-color: rgba(247, 246, 246, 0.07);
    backdrop-filter: blur(10px);
    box-shadow: 2px 2px 3px rgba(0,0,0,.3);
    color: white;
    font-size: 20px;
    padding: 15px;
    padding-left: 5px;
    padding-right: 0px;
    text-align: left;
    white-space: nowrap;
    position: relative;
}
.discovery__card__inner.episodes {
    padding: 15px;
    padding-top: 5px; 
    padding-bottom: 30px;
}
.discovery__card__inner.episodes.started {
    padding-bottom: 10px;
}
.discovery__card__inner.fullWidth {
    margin-left: 0px;
    margin-right: 0px;
    padding: 30px;
}
.discovery__card__inner.darker {
    //background-color: rgba(8, 9, 9, 0.3);
    //backdrop-filter: 20px;
}
.discovery__card__inner.contact {
    margin-bottom: 30px;
}
.discovery__card__inner.stories {
    padding-left: 15px; 
    padding-right: 15px; 
    padding-bottom: 0px; 
    overflow: hidden;
    backdrop-filter: blur(3px);
    box-shadow: 2px 2px 3px rgba(0,0,0,.3);
    background-color: rgba(8, 8, 9, 0.17);
}

.discovery__card__inner__left {
    display: inline-block;
    width: 10px;
    height: 40px;
    //position: relative;
    position: absolute;
    top: 50%;
    transform: translateY(-20px);
}
.discovery__card__inner__left.top {
    top: 15px;
    transform: none;
}

.discovery__card__handle {
    position: absolute;
    top: 0px;
    border-radius: 30px;
    background-color: rgba(247, 246, 246, 0.07); 
    box-shadow: 
        1px 1px 2px rgba(0,0,0,.2),
        -1px -1px 2px rgba(255,255,255,.2);
}
.discovery__card__handle.vertical {
    width: 7px;
    height: 40px;
}
.discovery__card__handle.horizontal {
    position: relative;
    width: 40px;
    height: 7px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.discovery__card__inner__right {
    display: inline-block;
    white-space: initial;
    padding-left: 20px;
    padding-right: 10px;
    width: calc(100% - 10px);
}

.discovery__card__title {
    font-variant: small-caps;
    font-size: 20px;
    color: white;
    font-weight: 800;
    margin-bottom: 15px;
    white-space: initial;
}
.discovery__card__title.contact {
    margin-bottom: 28px;
    font-size: 24px;
}
.discovery__card__title.--no_margin {
    margin-bottom: 0px;
}

.discovery__card__subtitle {
    font-variant: small-caps;
    font-size: 10px;
    line-height: 15px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.5);
    white-space: initial;
}
.discovery__card__subtitle.sizeText {
    font-size: 16px;
}
.discovery__card__subtitle.contact {
    font-size: 12px;
}

.discovery__card__logo {
    height: 100px;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.discovery__card__text {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    color: white;
    font-weight: 600;
    white-space: initial;
    //text-align: justify;
    //hyphens: auto;
}
.discovery__card__text.--notice {
    font-size: 10px;
    text-align: left;
    line-height: 12px;
    font-variant: small-caps;
    color: rgba(255, 255, 255, 0.5);
}
.discovery__card__text.--list {
    font-size: 22px;
    line-height: 26px;
    font-variant: small-caps;
}

.discovery__card__subtext {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 10px;
    line-height: 12px;
    color: rgba(255,255,255,.5);
    font-weight: 400;
    font-style: italic;
    white-space: initial;
    text-align: justify;
    hyphens: auto;
}

.discovery__card__livemessage {
    font-variant: small-caps;
    font-size: 10px;
    font-weight: bold;
    color: #F6B162;
    white-space: initial;
    text-align: right;
}

.discovery__card__button__container {
    margin-top: 15px;
    position: relative;
    height: 35px;
    padding: 0px;
}
.discovery__card__button {
    box-shadow: inset 2px 2px 2px rgba(255,255,255,.3);
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 10px;
    padding: 7px;
    right: 0px;
    display: inline-block;
    position: absolute;
    font-variant: small-caps;
    font-size: 12px;
    font-weight: 700;
}
.discovery__card__button:active {
    box-shadow: inset 2px 2px 2px rgba(0,0,0,.3);
}
.discovery__card__button.--membership {
    font-size: 20px;
}

.discovery__slides__logo__outer {
    white-space: nowrap;
    width: 135px;
    height: 110px;
    border-radius: 10px;
    margin-left: 15px;
    padding: 5px;
    background-color: rgba(247, 246, 246, 0.1); 
    backdrop-filter: blur(3px);
    box-shadow: 2px 2px 3px rgba(0,0,0,.3);
}

.discovery__slides__logo__inner {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin-left: 10px;
    background-image: url('https://avoidsociety.app/logos/white/pa1-logo-white.png');
    background-position: center;
    background-size: cover;
}

.container-back-button {
    position: fixed;
    width: 100%;
	top: 30px;
	z-index: 999;
    transition: opacity .4s;
    height: 50px;
}
.back-button {
    margin-left: 15px;
    margin-right: auto;
    width: 50px;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    color: white;
}
.back-button:active {
    background-color: rgba(255,255,255,.2);
}
.back-button.right {
    margin-left: auto;
    margin-right: 15px;
}
.back-button-icon {
	font-size: 30px;
	text-align: center;
	color: rgba(255,255,255,.5);
	position: relative;
	top: 50%;
}

.CLOSE__ICON {
    font-size: 30px;
	text-align: center;
	color: var(--eon-contrast-text);
	position: relative;
	top: 50%;
}


.container-down-button {
    position: fixed;
	right: 15px;
	bottom: 20px;
	z-index: 999;
	transition: opacity .4s;
	padding: 10px;
    border-radius: 10px;
    font-size: 20px;
}
.container-down-button:active {
    background-color: rgba(255,255,255,.2);
}

.button-icon-down-1 { animation: animationButton1 3s; animation-iteration-count: infinite; }
.button-icon-down-2 { animation: animationButton2 3s; animation-iteration-count: infinite; }
.button-icon-down-3 { animation: animationButton3 3s; animation-iteration-count: infinite; }
.button-icon-right-1 { animation: animationButton1 3s; animation-iteration-count: infinite; display: inline; }
.button-icon-right-2 { animation: animationButton2 3s; animation-iteration-count: infinite; display: inline; }
.button-icon-right-3 { animation: animationButton3 3s; animation-iteration-count: infinite; display: inline; }
@keyframes animationButton1 { 0% { opacity: 0; } 25% { opacity: 1; } 50% { opacity: 0; } 75% { opacity: 0; } 100% { opacity: 0; } }
@keyframes animationButton2 { 0% { opacity: 0; } 25% { opacity: 0; } 50% { opacity: 1; } 75% { opacity: 0; } 100% { opacity: 0; } }
@keyframes animationButton3 { 0% { opacity: 0; } 25% { opacity: 0; } 50% { opacity: 0; } 75% { opacity: 1; } 100% { opacity: 0; } }


.swipe-vertical-wobble {
    -webkit-animation: animation 1800ms linear 2 both;
    animation: animation 1800ms linear 2 both;
    animation-delay: 1s;
}
@-webkit-keyframes animation { 
    0% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    44.34% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    44.44% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    46.45% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -30.312, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -30.312, 0, 0, 1); }
    48.45% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -38.275, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -38.275, 0, 0, 1); }
    50.95% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -30.606, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -30.606, 0, 0, 1); }
    53.4% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -18.111, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -18.111, 0, 0, 1); }
    55.9% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -6.395, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -6.695, 0, 0, 1); }
    58.35% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.026, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.026, 0, 0, 1); }
    62.35% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -3.222, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -3.222, 0, 0, 1); }
    72.25% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.004, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.004, 0, 0, 1); }
    76.25% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.267, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.267, 0, 0, 1); }
    86.15% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90.16% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.022, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.022, 0, 0, 1); }
    100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}  
@keyframes animation { 
    0% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    44.34% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    44.44% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    46.45% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -30.312, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -30.312, 0, 0, 1); }
    48.45% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -38.275, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -38.275, 0, 0, 1); }
    50.95% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -30.606, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -30.606, 0, 0, 1); }
    53.4% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -18.111, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -18.111, 0, 0, 1); }
    55.9% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -6.395, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -6.695, 0, 0, 1); }
    58.35% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.026, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.026, 0, 0, 1); }
    62.35% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -3.222, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -3.222, 0, 0, 1); }
    72.25% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.004, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.004, 0, 0, 1); }
    76.25% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.267, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.267, 0, 0, 1); }
    86.15% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90.16% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.022, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.022, 0, 0, 1); }
    100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}

.eon__neuo__listitem {
    display: flex;
    align-items: stretch;
}
.eon__neuo__listitem:active {
    background-color: var(--eon-item-active);
}

.eon__neuo__listitem__avatar__outer {
    flex-basis: 110px;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.eon__neuo__listitem__avatar__inner {
    position: relative;
    display: inline-block;
    justify-self: center;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 3px solid var(--eon-bg);
    background-size: cover;
    background-position: center;
    /*
    box-shadow: 
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px var(--eon-shadow-04-top);
    */
}

.eon__neuo__listitem__title__outer {
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--eon-separation-line);
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

.eon__neuo__listitem__title__inner {
    align-self: center;
    color: var(--eon-contrast-text);
    font-variant: small-caps;
    font-size: 30px;
}

.eon__neuo__listitem__chevron__outer {
    flex-basis: 50px;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: stretch;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--eon-separation-line);
    display: flex;
    justify-content: center;
    padding-right: 10px;
}

.eon__neuo__listitem__chevron__inner {
    align-self: center;
    text-align: center;
    justify-self: center;
}

.eon__neuo__chevron {
    border-radius: 30px;
    /*
    box-shadow:
        1px 1px 2px var(--eon-shadow-04-low), 
        -1px -1px 2px rgba(255,255,255,.05);
    */
    font-weight: 800;
    text-align: center;
    background-color: var(--eon-bg);
    color: var(--eon-contrast-text-less);
    width: 22px;
    height: 22px;
    text-align: center;
    font-size: 12px;
    padding-top: 3px;
}