ion-toolbar {
    --background: none;
}

ion-list {
    --background: var(--eon-bg);
    background-color: var(--eon-bg) !important;
}

.eon__page__transition__backdrop {
    position: fixed;
    background-color: #f7f6f6;
    width: 100%;
    height: 100%;
    opacity: .7;
}

.eon__header {
    background-color: var(--eon-bg-60);
    backdrop-filter: blur(10px);
    padding-bottom: 1px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: visible;
    color: var(--eon-contrast-text);
}
.eon__header.collapsed {
    //box-shadow: 0px 10px 8px -9px rgba(0,0,0,.1) !important;
    box-shadow: 2px 2px 3px rgba(0,0,0,.07);
}

.eon__footer {
    background-color: var(--eon-bg-60);
    backdrop-filter: blur(10px);
    padding-bottom: 1px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: visible;
    color: var(--eon-contrast-text);
    position: absolute;
    bottom: 0px;
}

.eon__header__title__small {
    font-variant: small-caps;
    font-size: 20px;
    opacity: 0; 
    transition: opacity .4s;
    color: var(--eon-contrast-text);
}
.eon__header__title__small.fixed {
    opacity: 1;
    transition: none;
}

.eon__header__title__large {
    //height: 50px;
    font-size: 40px;
    line-height: 40px;
    font-variant: small-caps;
    font-weight: 600;
    padding-left: 15px;
    overflow: hidden;
    color: var(--eon-contrast-text);
}

.eon__header__buttons {
    border-radius: 10px;
    color: var(--eon-contrast-text);
}
.eon__header__buttons:active {
    //background-color: var(--eon-background-active);
}

.eon__header__buttons__button {
    border-radius: 10px;
}
.eon__header__buttons__button:active {
    background-color: var(--eon-background-active);
}

.ion-footer {
    --padding-top: 0px;
}

.eon__footer {
    //background-color: var(--eon-bg-60);
    //background-color: rgba(255,255,255,.3);
    //background-color: red;
    //background-color: rgba(255,255,255,.9);
    backdrop-filter: blur(10px);
    //box-shadow: -2px -2px 3px rgba(0,0,0,.07);
    padding-bottom: 1px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-width: 0px;
    overflow: visible;
    color: var(--eon-contrast-text);
    bottom: 0px;
    position: fixed;
    //padding: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
}

.EON__TOOLBAR__BUTTONS {
    color: white;
}

.EON__TEXT.--SUB {
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
}
.EON__TEXT {
    margin: 15px;
    margin-right: 15px;
    font-size: 18px;
    line-height: 27px;
    color: var(--eon-contrast-text);
    font-weight: 400;
}
.EON__TEXT.--MORE {
    color: var(--eon-contrast-text);
}
.EON__TEXT.--SECTION {
    font-size: 27px;
    line-height: 40px;
    color: var(--eon-contrast-text);
    font-weight: 500;
    font-variant: small-caps;
}
.EON__TEXT.--TITLE {
    font-size: 40px;
    line-height: 40px;
    color: var(--eon-contrast-text);
    font-weight: 600;
    font-variant: small-caps;
}
@font-face {
    font-family: 'Dananjaya';
    src: url("../src/assets/fonts/Dananjaya.ttf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Hazel';
    src: url("../src/assets/fonts/Hazel-Regular.otf");
    font-weight: normal;
    font-style: normal;
}
.EON__TEXT.--LOGO {
    text-align: center; 
    color: white;
    font-family: 'Hazel';
    font-size: 30px;
    line-height: 30px;
}
.EON__TEXT.--INPUT {
    background-color: var(--eon-bg-more);
    margin-left: 0px;
    padding-left: 15px;
    margin-right: 0px;
    padding-right: 15px;

    width: 95%;
    
    border-right-style: solid;
    border-right-width: 3px;
    border-right-color: var(--eon-bg-less);
}
.EON__TEXT.--INPUT.--LEFT {
    margin-right: 10%;
    width: 90%
}
.EON__TEXT.--INPUT.--RIGHT {
    margin-left: 10%;
    width: 90%;
    border-right-style: none;
    border-left-style: solid;
    border-left-width: 3px;
    border-left-color: var(--eon-bg-less);
}
.EON__TEXT.--INPUT.--RIGHT:focus-within {
    border-left-color: var(--eon-color-positive-1);
}
.EON__TEXT.--INPUT:focus-within {
    border-right-color: var(--eon-color-positive-1);
}
.EON__TEXT.--INPUT.--WARNING {
    color: var(--eon-contrast-text-less);
}
.EON__TEXT.--INPUT.--WARNING:focus-within {
    border-right-color: var(--eon-color-warning-2);
}
.EON__TEXT.--INPUT.--DANGER {
    color: var(--eon-contrast-text-less);
}
.EON__TEXT.--INPUT.--DANGER:focus-within {
    border-right-color: var(--eon-color-danger-1);
}
.EON__TEXT.--INPUT.--SELECTED {
    background-color: var(--eon-bubble-bg);
    color: var(--eon-box-contrast);
    border-color: var(--eon-box-contrast);
}
.EON__TEXT.--INPUT.--CENTER {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    border-style: solid;
    border-width: 1px;
    border-right-width: 3px;
    border-color: var(--eon-bg-less);
}
.EON__TEXT.--INPUT.--CENTER:focus-within {
    border-color: var(--eon-color-positive-1);
}
ion-input.EON__TEXT.--INPUT {
    padding-left: 0px;
    padding-right: 0px;
    --padding-start: 15px !important;
    --padding-end: 15px !important;
}
ion-textarea.EON__TEXT.--INPUT {
    padding-left: 0px;
    padding-right: 0px;
    --padding-start: 15px !important;
    --padding-end: 15px !important;
}
.EON__TEXT.--POSITIVE { color: var(--eon-color-positive-1); }
.EON__TEXT.--WARNING { color: var(--eon-color-warning-2); }
.EON__TEXT.--DANGER { color: var(--eon-color-danger-1); }

.EON__TEXT .--POSITIVE { color: var(--eon-color-positive-1); }
.EON__TEXT .--WARNING { color: var(--eon-color-warning-1); }
.EON__TEXT .--DANGER { color: var(--eon-color-danger-1); }

.EON__BOX {
    margin: 15px;
    margin-right: 15px;
    padding: 15px;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: var(--eon-bg-less);
    color: var(--eon-contrast-text-less);
    background-color: var(--eon-bg-more);
}
.EON__BOX.--COVER {
    min-height: 150px;
    border-color: var(--eon-bg-more);
    background-position: center;
    background-size: cover;
}
.EON__BOX.--COVER.--GRID {
    min-height: 150px;
    margin: 0px;
    margin-right: 0px;
    border-radius: 0px;
    padding: 0px;
}
.EON__BOX.--SELECTED {
    background-color: var(--eon-bubble-bg);
    color: var(--eon-box-contrast);
    border-color: var(--eon-box-contrast);
}
.EON__BOX.--SELECTED .EON__BUTTON {
    background-color: var(--eon-bubble-bg);
    color: var(--eon-box-contrast);
    border-color: var(--eon-box-contrast);
}
.EON__BOX.--SELECTED.--PRIVATE {
    color: var(--eon-contrast-text-less);
    background-color: var(--eon-bg-more);
    border-color: var(--eon-bubble-bg);
}
.EON__BOX.--HOVER {
    transition: transfrom .4s;
}
.EON__BOX.--HOVER:active {
    transform: scale(.95);
}
.EON__BOX__TITLE {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    font-variant: small-caps;
}
.EON__BOX__TITLE.--IMPORTANT {
    color: var(--eon-contrast-text);   
}
.EON__BOX__MAIN {
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;
}
.EON__BOX__NAME {
    text-align: left; 
    color: white;
    font-family: 'Hazel';
    font-size: 20px;
    line-height: 20px;
    background-color: rgba(0,0,0,.5);
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 5px !important;
    padding-bottom: 7px !important;
    font-weight: 200;
}
ion-grid.EON__BOX__NAME__GRID {
    margin: 0px !important;
    padding: 0px !important;
    height: 150px;
    ion-row { margin: 0px; padding: 0px; }
    ion-col { margin: 0px; padding: 0px; }
}
.EON__BOX__LOGO {
    text-align: center; 
    color: white;
    font-family: 'Hazel';
    font-size: 40px;
    line-height: 40px;
}
ion-grid.EON__BOX__LOGO {
    margin: 0px; padding: 0px;
    ion-row { margin: 0px; padding: 0px; }
    ion-col { margin: 0px; padding: 0px; }
}

ion-chip.EON__CHIP {
    background-color: var(--eon-bg-more);
    color: var(--eon-contrast-text-less);
}
ion-chip.EON__CHIP.--SELECTED {
    background-color: #518070;
    color: #C9FCE9;
}
.EON__CHIP.--HOVER {
    transition: transfrom .4s;
}
.EON__CHIP.--HOVER:active {
    transform: scale(.95);
}

ion-grid.EON__GRIDBUTTON__GRID{ margin: 0px; padding: 0px; margin-top: 30px; margin-bottom: 30px; }
.EON__GRIDBUTTON__GRID ion-row { margin: 0px; padding: 0px; }
.EON__GRIDBUTTON__GRID ion-col { margin: 0px; padding: 10px; }
.EON__GRIDBUTTON {
    background-color: var(--eon-bg-more);
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-size: 0px;
}
.EON__GRIDBUTTON:active {
    background-color: var(--eon-bg-less);
}
.EON__GRIDBUTTON.--COVER {
    background-size: cover;
    background-position: center;
    .EON__GRIDBUTTON__ICON {
        color: rgba(255,255,255,.7);
    }
    .EON__GRIDBUTTON__TITLE {
        color: rgba(255,255,255,.7);
    }
}
.EON__GRIDBUTTON.--COVER:active {
    filter: grayscale(.8);
}
.EON__GRIDBUTTON.--COVER.--HOVER:active {
    transform: scale(.95);
}
.EON__GRIDBUTTON__ICON {
    color: var(--eon-color-positive-1);
    text-align: center;
    font-size: 27px;
    line-height: 40px;
    font-weight: 500;
    font-variant: small-caps;    
}
.EON__GRIDBUTTON__ICON.--POSITIVE {
    color: var(--eon-color-positive-1);
}
.EON__GRIDBUTTON__ICON.--WARNING {
    color: var(--eon-color-warning-2);
}
.EON__GRIDBUTTON__ICON.--DANGER {
    color: var(--eon-color-danger-1);
}
.EON__GRIDBUTTON__ICON.--NEUTRAL {
    color: var(--eon-contrast-text);
}
.EON__GRIDBUTTON.--POSITIVE {
    .EON__GRIDBUTTON__ICON {
        color: var(--eon-color-positive-1);
    }
}
.EON__GRIDBUTTON.--WARNING {
    .EON__GRIDBUTTON__ICON {
        color: var(--eon-color-warning-2);
    }
}
.EON__GRIDBUTTON.--DANGER {
    .EON__GRIDBUTTON__ICON {
        color: var(--eon-color-danger-1);
    }
}
.EON__GRIDBUTTON.--NEUTRAL {
    .EON__GRIDBUTTON__ICON {
        color: var(--eon-color-neutral-1);
    }
}

.EON__GRIDBUTTON__LOGO {
    background-size: cover;
    background-position: center;
    height: 80px;
    width: 100%;
}

.EON__GRIDBUTTON__COVER {
    width: calc(100% - 10px);
    height: 100px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 5px;
    margin-right: 5px;
}

.EON__GRIDBUTTON__TITLE {
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    color: var(--eon-contrast-text);
    font-weight: 600;
    font-variant: small-caps;
}

.EON__GRIDBUTTON__TITLE.--SMALL {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: var(--eon-contrast-text);
    font-weight: 500;
    font-variant: small-caps;
}

.EON__GRIDBUTTON.--LEFT {
    padding-left: 15px;
    padding-right: 15px;
    .EON__GRIDBUTTON__ICON {
        text-align: left;
    }
    .EON__GRIDBUTTON__TITLE {
        text-align: left;       
    }
    .EON__TEXT {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.EON__GRIDBUTTON__GRID.--AWARD {
    margin-top: 15px;
    margin-bottom: 15px;
    .EON__GRIDBUTTON {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .EON__GRIDBUTTON__ICON {

    }
    .EON__GRIDBUTTON__TITLE {
        font-size: 12px;
        color: var(--eon-contrast-text-less);
    }
}

ion-tab-bar.EON__TABBAR {
    //--background: var(--eon-bg) !important;
    --background: var(--eon-bg-more) !important;
    //--border: 3px solid var(--eon-bg-more) !important;
    --border: none;
    padding-top: 10px;
    padding-bottom: 20px;
    @supports (-webkit-touch-callout: none) {
        padding-bottom: 30px;
    }
}
.EON__TABBUTTON {
    --color: var(--eon-contrast-text-less);
    --color-focused: var(--eon-color-positive-1);
    --color-selected: var(--eon-color-positive-1);
    .EON__TABBUTTON__ICON {
        font-size: 26px;
        margin-bottom: 2px;
    }
    .EON__TABBUTTON__LABEL {
        margin-top: 2px;
        font-size: 10px;
        font-weight: 500;
    }
}
.EON__TABBUTTON.--ACTIVE {
    --color: var(--eon-color-positive-1) !important;
}
.EON__TABBUTTON:hover {
    --color: var(--eon-color-positive-1) !important;
    transform: scale(.9);
}

.EON__ORDERINPUT {
    width: 50px;
    max-width: 50px;
    background-color: coral;
}

.RELEASEINDICATOR {
    border-radius: 100%;
    width: 5px;
    height: 5px;
    float: right;
    background-color: var(--eon-color-danger-1);
}
.--SELECTED .RELEASEINDICATOR {
    background-color: var(--eon-color-positive-3);
}
.--PRIVATE .RELEASEINDICATOR {
    background-color: var(--eon-color-positive-3);
}

.EON__AVATAR.--EPISODE {
    --border-radius: 10px;
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.EON__FAB {
    ion-fab-button {
        --background: var(--eon-color-positive-1);
        --background-activated: var(--eon-color-positive-2);
        --background-hover: var(--eon-color-positive-4);
        --border-radius: 10px;
        --box-shadow: 0px 1px 2px 0px rgba(0,0,0,.3), 0px 1px 3px 1px rgba(0,0,0,.15);
        --color: var(--eon-contrast-text);
    }
}

.EON__ITEMBUTTON {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    font-variant: small-caps;
    color: var(--eon-contrast-text);
    background-color: var(--eon-bg);
    --background: var(--eon-bg);
    --border-color: var(--eon-contrast-text-less) !important;
    ion-button {
        --border-color: var(--eon-contrast-text-less) !important;
    }
    ion-label {
        --color: var(--eon-contrast-text) !important;
    }
}
.EON__ITEMBUTTON:active {
    --background: var(--eon-bg-less);
}

.EON__ITEMBUTTON.--POSITIVE {
    //--border-color: var(--eon-color-positive-1) !important;
    color: var(--eon-color-positive-2);
    ion-label {
        --color: var(--eon-color-positive-2) !important;
    }
    ion-icon {
        color: var(--eon-color-positive-1);
    }
    ion-button {
        //--border-color: var(--eon-color-positive-1);
        --color: var(--eon-color-positive-1);
    }
}
.EON__ITEMBUTTON.--WARNING {
    //--border-color: var(--eon-color-warning-2) !important;
    color: var(--eon-color-warning-2);
    ion-label {
        --color: var(--eon-color-warning-2) !important;
    }
    ion-icon {
        color: var(--eon-color-warning-2);
    }
    ion-button {
        //--border-color: var(--eon-color-warning-2);
        --color: var(--eon-color-warning-2);
    }
}
.EON__ITEMBUTTON.--WARNING.--MASSIVE {
    --background: var(--eon-color-warning-1);
    //--border-color: var(--eon-color-warning-1);
    color: var(--eon-bg);
    ion-label {
        color: var(--eon-bg) !important;
    }
    ion-icon {
        color: var(--eon-bg) !important;
    }
    ion-button {
        color: var(--eon-bg) !important;
        //--border-color: var(--eon-color-warning-1) !important;
    }
    .EON__ITEMBUTTON__SUB {
        color: var(--eon-bg) !important;
        font-weight: 600;
    }
}
.EON__ITEMBUTTON.--DANGER {
    //--border-color: var(--eon-color-danger-1) !important;
    color: var(--eon-color-danger-1);
    ion-label {
        --color: var(--eon-color-danger-1) !important;
    }
    ion-icon {
        color: var(--eon-color-danger-1);   
    }
    ion-button {
        //--border-color: var(--eon-color-danger-1);
        --color: var(--eon-color-danger-1);
    }    
}

.EON__ITEMBUTTON.--NEUTRAL {
    //--border-color: var(--eon-contrast-text) !important;
    color: var(--eon-contrast-text);
    ion-label {
        --color: var(--eon-contrast-text) !important;
    }
    ion-icon {
        color: var(--eon-contrast-text);   
    }
}

.EON__ITEMBUTTON.--LESS {
    --border-color: var(--eon-contrast-text-less) !important;
    color: var(--eon-contrast-text-less);
    ion-label { --color: var(--eon-contrast-text-less) !important; }
    ion-icon { color: var(--eon-contrast-text-less); }
}

.EON__ITEMBUTTON.--NOLINES {
    --border-color: var(--eon-bg) !important;
}

.EON__ITEMBUTTON__SECONDARY {
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color: var(--eon-contrast-text);
}

.EON__ITEMBUTTON__SUB {
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    font-variant: normal;
    color: var(--eon-contrast-text-less);
}
.EON__ITEMBUTTON__SUB.--MORE {
    font-weight: 600;
    font-variant: small-caps;
    color: var(--eon-contrast-text);
}

.EON__ITEMBUTTON .--REMOVECOLOR {
    color: var(--eon-contrast-text);
}

.EON__MENUCONTAINER {
    margin-top: 60px;
    .EON__TEXT.--SECTION {
        margin-top: 0px;
        margin-bottom: 0px;
        //margin-left: 8px;
        font-size: 20px;
        line-height: 30px;
        //color: var(--eon-color-positive-3);
        background-color: var(--eon-color-positive-1);
        margin-left: 0px;
        padding-left: 8px;
        margin-right: 0px;
        padding-right: 8px;
        background-color: var(--eon-bg-less);
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .CONTAINER__EPISODEBUTTON {
        background-color: var(--eon-bg-more);
        //margin-left: 5px;
        //margin-right: 5px;
        //padding-top: 2px;
        //padding-bottom: 2px;
        //border-radius: 10px;
    }    
    .EON__ITEMBUTTON {
        --border-color: var(--eon-bg-more);
        border-bottom-width: 5px !important;
        background-color: var(--eon-bg-more);
        --background: var(--eon-bg-more);
        --padding-start: 10px !important;
        --padding-end: 5px !important;
        --inner-padding-end: 0px !important;
        font-size: 14px; 
        --min-height: 0px;       
    }
    .EON__ITEMBUTTON:active {
        --background: var(--eon-bg-less);
    }  
    ion-avatar {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        border-radius: 5px;
        text-align: center;
        font-size: 27px;
        line-height: 27px;
        --min-height: 0px;
    }    
    .EON__MENU__COVER {
        border-radius: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .COSMOSBUTTON {
        //border-radius: 10px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        --padding-start: 10px !important;
        --padding-end: 5px !important;
        --inner-padding-end: 0px !important;
        ion-label {
            padding: 0px;
            --padding: 0px;
        }
    }
}

.CHECKLIST ion-item {
    opacity: .5;
}
.CHECKLIST ion-item ion-avatar {
    opacity: .2;
}

.CHECKLIST ion-item.--SELECTED {
    opacity: 1;
    --border-color: var(--eon-color-positive-1);
    ion-label {
        --color: var(--eon-color-positive-1);
    }
    ion-icon {
        color: var(--eon-color-positive-1);
    }
    ion-label .EON__ITEMBUTTON__SUB {
        color: var(--eon-contrast-text) !important;
    }
    ion-avatar {
        opacity: 1;
    }    
}

.CHECKLIST ion-item.--SELECTED.--WARNING {    
    --border-color: var(--eon-color-warning-2);
    ion-label { --color: var(--eon-color-warning-2); }
    ion-icon { color: var(--eon-color-warning-2); }
}

.CHECKLIST ion-item.--SELECTED.--DANGER {
    --border-color: var(--eon-color-danger-1);
    ion-label { --color: var(--eon-color-danger-1); }
    ion-icon { color: var(--eon-color-danger-1); }
}

.CHECKLIST .EON__ITEMBUTTON.--PLUSBUTTON {
    opacity: 1;
    --border-color: var(--eon-contrast-text-less) !important;
    ion-item {
        opacity: 1;
        --border-color: var(--eon-contrast-text-less) !important;
    }
    ion-avatar {
        opacity: 1;
    }
    ion-label {
        color: var(--eon-contrast-text-less);
    }
    ion-icon {
        color: var(--eon-contrast-text-less);
    }
}


.EON__ITEM__TITLE {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    font-variant: small-caps;
    color: var(--eon-contrast-text);
}
.EON__ITEM__SUB {
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    color: var(--eon-contrast-text-less);
}
.EON__ITEM__LINK {
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    font-variant: small-caps;
    color: var(--eon-contrast-text);
}

ion-segment-button {
    //--background-checked: rgb(201,236,128,.5);
    --indicator-color: var(--eon-bg-more);
}

.EON__SEGMENT {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    width: calc(100% - 30px);
    color: var(--eon-contrast-text);
}

.EON__STATGRID__NUMBER {
    //color: var(--eon-contrast-text);
    color: var(--eon-color-positive-1);
    text-align: center;
    font-size: 27px;
    line-height: 40px;
    font-weight: 500;
    font-variant: small-caps;
}

.EON__STATGRID__DESCRIPTION {
    color: var(--eon-contrast-text-less);
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
}

.EON__CHECKBOX {
    --checkmark-color: var(--eon-contrast-text);
    --background: var(--eon-bg-more);
    --background-checked: var(--eon-color-positive-1);
    --border-color: var(--eon-color-positive-1);
    --border-color-checked: var(--eon-color-positive-2);
}

.EON__BUTTON {
    //background-color: #0C0C0C;
    //color: var(--eon-contrast-text);
    //border-color: #030303;
    background-color: var(--eon-bg-morish);
    color: var(--eon-contrast-text);
    border-color: var(--eon-bg-more);
    padding: 10px;
    font-size: 12px;
    text-align: center;
    font-variant: small-caps;
    font-weight: 500;
    border-radius: 10px;
    border-style: solid;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;

    border-top-width: 1px;
    border-bottom-width: 3px;

    border-left-width: 1px;
    border-right-width: 3px;
}
.EON__BUTTON:active {
    border-top-width: 2px !important;
    border-bottom-width: 2px !important;

    border-left-width: 2px !important;
    border-right-width: 2px !important;
}
.EON__BUTTON.--POSITIVE {
    color: var(--eon-color-positive-1);
    border-color: var(--eon-color-positive-1);
}
.EON__BUTTON.--POSITIVE.--COLOR {
    background-color: var(--eon-color-positive-1);
    //color: var(--eon-bg-morish);
    color: var(--eon-contrast-text);
    border-color: var(--eon-bg-morish);
}
.EON__BUTTON.--WARNING {
    color: var(--eon-color-warning-2);
    border-color: var(--eon-color-warning-2);
}
.EON__BUTTON.--DANGER {
    color: var(--eon-color-danger-1);
    border-color: var(--eon-color-danger-1);
}
.EON__BUTTON.--LESS {
    background-color: var(--eon-bg);
    border-color: var(--eon-bg-more);
}
.EON__BUTTON.--INACTIVE {
    background-color: var(--eon-bg);
    border-color: var(--eon-bg-more);
    color: var(--eon-bg-more);
}
.EON__BUTTON.--SMALL {
    padding: 5px;
    margin: 5px;
}
.EON__BUTTON.--LARGE {
    font-size: 24px;
}
.EON__BUTTON.--ICON {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px;
}
.EON__BUTTON.--POPIN {
    animation: popin 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: scale(0.5);
    opacity: 0;
    animation-fill-mode: forwards;
}
@keyframes popin {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
.EON__BUTTON.--INLINE {
    display: inline-block;
}
.EON__BUTTON.--INLINE:active {
    display: inline-block;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 2px;
    border-bottom-width: 3px;
    background-color: var(--eon-color-positive-2);
}
.EON__BUTTON.--TOGGLE {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px;    
}
.EON__BUTTON.--TOGGLE:active {
    background-color: var(--eon-color-positive-2);
}
.EON__BUTTON.--TOGGLE.--ACTIVE {
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 2px;
    border-bottom-width: 3px;
    background-color: var(--eon-color-positive-2);
}
.EON__BUTTON.__TOGGLE__WARNING:active {
    background-color: var(--eon-color-warning-2);
}
.EON__BUTTON.__TOGGLE__WARNING.--ACTIVE {
    background-color: var(--eon-color-warning-2);
}
.EON__BOX .EON__BUTTON {
    color: var(--eon-contrast-text-less);
    background-color: var(--eon-bg-more);
    border-color: var(--eon-contrast-text-less);
}
.eon__neuo__card .EON__BUTTON {
    color: var(--eon-contrast-text-less);
    background-color: var(--eon-card-bg);
    border-color: var(--eon-contrast-text-less);
}
.EON__BUTTON.--UNLOCK {
    font-size: 30px;
    line-height: 45px;
    color: var(--eon-contrast-text);
    ion-grid { padding: 0px; margin: 0px; }
    ion-row { padding: 0px; margin: 0px; }
    ion-col { padding: 0px; margin: 0px; padding-left: 5px; padding-right: 5px; }
}
.EON__BUTTON__UNLOCK__COVER {
    width: 70px;
    height: 70px;
    margin: auto;
    border-radius: 100%;
    background-image: url('https://avoidsociety.app/cover/square/pa3.jpg');
    background-position: center;
    background-size: cover;
    border-style: solid;
    border-width: 2px;
    border-color: var(--eon-bg-more);
}

.EON__BUTTON__UNLOCK__LOGO {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}
.EON__BUTTON__UNLOCK__TITLE {
    text-align: left;
    font-size: 27px;
    line-height: 40px;
    color: var(--eon-contrast-text);
}
.EON__BUTTON__UNLOCK__ACTION {
    text-align: left;
    font-size: 18px;
    line-height: 27px;
    color: var(--eon-contrast-text-less);
}
.EON__BUTTON.--SELECTED {
    background-color: #518070;
    color: #C9FCE9;
    border-color: #C9FCE9;    
}
.EON__BUTTON.--SELECTED.--WARNING {
    background-color: var(--eon-color-warning-2);
    color: var(--eon-color-warning-3);
    border-color: var(--eon-color-warning-3);
}
.EON__BUTTON.--SELECTED.--DANGER {
    background-color: var(--eon-color-warning-2);
    color: var(--eon-color-warning-3);
    border-color: var(--eon-color-warning-3);
}

.COVERHEADER {
    background-position: center;
    background-size: cover;
    .COVERHEADER__LOGO {
        width: 100%;
        height: 100px;
        transform: scale(1.7);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}
.COVERHEADER .eon__header__title__large {
    height: 300px;
}
.COVERHEADER.--SMALL .eon__header__title__large {
    height: 150px;
}


    .MOTIONHEADER {
        background-position: center;
        background-size: cover;
        .MOTIONHEADER__LOGO {
            width: 100%;
            height: 100px;
            transform: scale(1.7);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        //height: calc(100vw - env(safe-area-inset-top));
        height: 75vw;
    }
    .MOTIONHEADER.--MOTION {
        background: none;
        background-image: none !important;
        transition: none;
    }
    .MOTIONHEADER__VIDEO__CONTAINER {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: calc(75vw + env(safe-area-inset-top));
        overflow: hidden;
    }
    .MOTIONHEADER__VIDEO__INNER {
        width: 100vw !important;
        height: 75vw !important;
        top: calc((75vw - (env(safe-area-inset-top) * 0.5)) * 0.5);
        background-color: var(--eon-bg-more);
    }
    .MOTIONHEADER__VIDEO {
        position: absolute;
        top: -25vw;
        width: 100%;
    }
    .MOTIONHEADER__HUB {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        //height: calc(100vw + env(safe-area-inset-top));
        height: 75vw;
    }
    .MOTIONHEADER__TITLE {
        height: calc(75vw - 90px - env(safe-area-inset-top));
    }

@media only screen and (min-width: 640px) {
    .MOTIONHEADER {
        background-position: center;
        background-size: cover;
        .MOTIONHEADER__LOGO {
            width: 100%;
            height: 100px;
            transform: scale(1.7);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        height: 278px;
    }
    .MOTIONHEADER.--MOTION {
        background: none;
        background-size: 0px;
        transition: none;
    }
    .MOTIONHEADER__VIDEO__CONTAINER {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: calc(278px + env(safe-area-inset-top));
        overflow: hidden;
    }
    .MOTIONHEADER__VIDEO__INNER {
        width: 100%;
        top: calc((278px - (env(safe-area-inset-top) * 0.5)) * 0.5);
        top: -100px;
    }
    .MOTIONHEADER__VIDEO {
        position: absolute;
        top: -92px;
        width: 100%;
    }
    .MOTIONHEADER__HUB {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        //height: calc(278px + env(safe-area-inset-top));
        height: 278px;
    }
    .MOTIONHEADER__TITLE {
        height: calc(278px - 90px - env(safe-area-inset-top));
    }
}

.EON__SIDEBOX {
    background-color: #0C0C0C;
    border-color: #030303;
    margin: 15px;
    margin-left: 0px;
    padding: 15px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
}
.EON__SIDEBOX__TITLE {
    font-size: 42px; 
    line-height: 63px;
    color: var(--eon-contrast-text);
    font-variant: small-caps;
}
.EON__SIDEBOX__MAIN {
    font-size: 18px !important;
    line-height: 27px !important;
    color: var(--eon-contrast-text-less);
}

ion-grid.GRID__NOPADDING { margin: 0px; padding: 0px; }
.GRID__NOPADDING ion-row { margin: 0px; padding: 0px; }
.GRID__NOPADDING ion-col { margin: 0px; padding: 0px; }

.EON__CONTAINER.--DANGER {
    --eon-bg: var(--eon-color-danger-2);
    --eon-contrast-text: white;
    --eon-contrast-text-less: white;
    background-color: var(--eon-color-danger-2);
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    ion-item {
        --border-color: white;
    }
    .EON__ITEMBUTTON {
        //--background: var(--eon-color-danger-1) !important;
    }
    //--eon-color-danger-1: cornflowerblue;
}

.EON__THUMBNAIL {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
}

/*
.EON__CONTAINER.--COLOR6 { --eon-color-positive-1: #325F68; }
.EON__CONTAINER.--COLOR5 { --eon-color-positive-1: #43756F; }
.EON__CONTAINER.--COLOR4 { --eon-color-positive-1: #5d8973; }
.EON__CONTAINER.--COLOR3 { --eon-color-positive-1: #799b77; }
.EON__CONTAINER.--COLOR2 { --eon-color-positive-1: #9bac7c; }
.EON__CONTAINER.--COLOR1 { --eon-color-positive-1: #c0bc84; }
*/

/*
.EON__CONTAINER {
    .EON__TEXT {
        --eon-color-danger-1: white;
        --eon-color-danger-2: white;
        --eon-color-danger-3: white;
    }
    .EON__ITEMBUTTON {
        --eon-color-danger-1: white;
        --eon-color-danger-2: white;
        --eon-color-danger-3: white;
    }
    --eon-contrast-text: white;
    --eon-contrast-text-less: white;
    padding-top: 15px;
    padding-bottom: 15px;
}
.EON__CONTAINER.--DANGER1 {
    background-color: var(--eon-color-danger-1) !important;
    --eon-bg: var(--eon-color-danger-1) !important;
}
.EON__CONTAINER.--DANGER2 {
    background-color: var(--eon-color-danger-2);
    --eon-bg: var(--eon-color-danger-2);
}
*/

.EON__SEPARATORICON {
    text-align: center;
    font-size: 40px;
    color: var(--eon-bg-more);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.EON__CARD {
    --background: var(--eon-bg-more);
    ion-card-subtitle {
        font-size: 12px;
        line-height: 12px;
        font-weight: 300;
        color: var(--eon-contrast-text-less);
    }
    ion-card-title {
        font-size: 40px;
        line-height: 40px;
        color: var(--eon-contrast-text);
        font-weight: 600;
        font-variant: small-caps;
    }
    ion-card-content {
        font-size: 12px;
        line-height: 18px;
        color: var(--eon-contrast-text-less);
        font-weight: 400;
    }
    ion-item {
        background-color: var(--eon-bg-more) !important;
        --background: var(--eon-bg-more) !important;
        ion-label {
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
            font-variant: small-caps;
        }
    }
    ion-item:active {
        background-color: var(--eon-bg) !important;
        --background: var(--eon-bg) !important;
    }
    .BACKDROPICON {
        color: var(--eon-contrast-text);
        height: 0px;
        width: 100%;
        text-align: right;
        overflow: visible;
        position: absolute;
        bottom: 90px;
        i {
            font-size: 100px;
        }
    }
}
.EON__CARD.--POSITIVE {
    --background: var(--eon-color-positive-1);
    ion-card-subtitle {
        color: var(--eon-contrast-text);
    }
    ion-card-content {
        color: var(--eon-contrast-text);
    }
    ion-item {
        --background: var(--eon-color-positive-1) !important;
    }
    ion-item:active {
        --background: var(--eon-color-positive-2) !important;        
    }
    img {
        border-width: 5px;
        border-style: solid;
        border-color: var(--eon-color-positive-1);
        border-radius: 10px;
    }
}
.EON__CARD.--GRADIENTCOLOR {
    color: white;
    ion-card-title { color: white; }
    ion-card-subtitle { color: white; }
    ion-card-content { color: white; }
    img {
        border-width: 5px;
        border-style: solid;
        border-color: white;
        border-radius: 10px;
    }    
}
.EON__CARD.--GRADIENTCOLOR.--1 { 
    --background: var(--eon-color-gradient-1);
    .BACKDROPICON { i { color: var(--eon-color-gradient-2); } }
}
.EON__CARD.--GRADIENTCOLOR.--2 { 
    --background: var(--eon-color-gradient-2);
    .BACKDROPICON { i { color: var(--eon-color-gradient-3); } }
}
.EON__CARD.--GRADIENTCOLOR.--3 { 
    --background: var(--eon-color-gradient-3);
    .BACKDROPICON { i { color: var(--eon-color-gradient-4); } }
}
.EON__CARD.--GRADIENTCOLOR.--4 { 
    --background: var(--eon-color-gradient-4);
    .BACKDROPICON { i { color: var(--eon-color-gradient-5); } }
}
.EON__CARD.--GRADIENTCOLOR.--5 { 
    --background: var(--eon-color-gradient-5);
    .BACKDROPICON { i { color: var(--eon-color-gradient-6); } }
}
.EON__CARD.--GRADIENTCOLOR.--6 { 
    --background: var(--eon-color-gradient-6);
    .BACKDROPICON { i { color: var(--eon-color-gradient-7); } }
}
.EON__CARD.--GRADIENTCOLOR.--7 { 
    --background: var(--eon-color-gradient-7);
    .BACKDROPICON { i { color: var(--eon-color-gradient-8); } }
}
.EON__CARD.--GRADIENTCOLOR.--8 { 
    --background: var(--eon-color-gradient-8);
    .BACKDROPICON { i { color: var(--eon-color-gradient-9); } }
}
.EON__CARD.--GRADIENTCOLOR.--9 { 
    --background: var(--eon-color-gradient-9);
    .BACKDROPICON { i { color: var(--eon-color-gradient-10); } }
}
.EON__CARD.--GRADIENTCOLOR.--10 { 
    --background: var(--eon-color-gradient-10);
    .BACKDROPICON { i { color: var(--eon-color-gradient-11); } }
}
.EON__CARD.--GRADIENTCOLOR.--11 { 
    --background: var(--eon-color-gradient-11);
    .BACKDROPICON { i { color: var(--eon-color-gradient-12); } }
}
.EON__CARD.--GRADIENTCOLOR.--12 { 
    --background: var(--eon-color-gradient-12);
    .BACKDROPICON { i { color: var(--eon-color-gradient-13); } }
    .EON__BUTTON {
        background-color: var(--eon-color-gradient-12);
        color: white;
        border-color: var(--eon-color-gradient-13);
    }
}

.EON__CARD.--WARNING {
    --background: var(--eon-color-warning-1);
    ion-card-title {
        color: #000;
    }
    ion-card-subtitle {
        color: #000;
    }
    ion-card-content {
        color: #000;
    }
    ion-item {
        --background: var(--eon-color-warning-1) !important;
        color: #000;
    }
    ion-item:active {
        --background: var(--eon-color-warning-2) !important;        
    }
    img {
        border-width: 5px;
        border-style: solid;
        border-color: var(--eon-color-warning-1);
        border-radius: 10px;
    }
    .EON__ITEMBUTTON {
        --border-color: #000 !important;
        color: #000 !important;
        ion-label {
            --color: #000 !important;
        }
        ion-icon {
            color: #000 !important;
        }
        ion-button {
            --border-color: #000 !important;
            --color: #000 !important;
        }
    }
    .EON__TEXT.--DANGER {
        --color: #000 !important;
        color: #000 !important;
    }
    .EON__TEXT.--INPUT {
        background-color: var(--eon-color-warning-3);
        border-right-color: var(--eon-color-warning-2);
        color: #000;
    }
    .EON__TEXT.--INPUT:focus-within {
        border-right-color: #000;
    }
    .BACKDROPICON {
        color: #000;
        opacity: 0.3;
    }
}
.EON__CARD.--DANGER {
    --background: var(--eon-color-danger-1);
    ion-card-subtitle {
        color: var(--eon-contrast-text);
    }
    ion-card-content {
        color: var(--eon-contrast-text);
    }
    ion-item {
        --background: var(--eon-color-danger-1) !important;
    }
    ion-item:active {
        --background: var(--eon-color-danger-2) !important;        
    }
    img {
        border-width: 5px;
        border-style: solid;
        border-color: var(--eon-color-danger-1);
        border-radius: 10px;
    }
    .EON__ITEMBUTTON {
        --border-color: var(--eon-contrast-text) !important;
        color: var(--eon-contrast-text) !important;
        ion-label {
            --color: var(--eon-contrast-text) !important;
        }
        ion-icon {
            color: var(--eon-contrast-text) !important;
        }
        ion-button {
            --border-color: var(--eon-contrast-text) !important;
            --color: var(--eon-contrast-text) !important;
        }
    }   
    .EON__TEXT.--DANGER {
        --color: var(--eon-contrast-text) !important;
        color: var(--eon-contrast-text) !important;
    }
    .EON__TEXT.--INPUT {
        background-color: var(--eon-color-danger-1);
        border-right-color: var(--eon-color-danger-2);
        color: var(--eon-contrast-text) !important;
    }
    .EON__TEXT.--INPUT:focus-within {
        border-right-color: var(--eon-color-danger-3) !important;
    }      
}
.EON__CARD.--ADDON {
    --background: var(--eon-bg-less);
    padding-top: 0px;
    padding-bottom: 0px;
    ion-card-content {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 10px;
        line-height: 10px;
        opacity: .6;
    }    
}
.EON__CARD.--WARNING.--ADDON {
    --background: var(--eon-color-warning-2);
}
.EON__CARD.--FULL {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    ion-card-header {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }    
    ion-card-title {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
    ion-card-content {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
.EON__CARD.--BOTTOMFLAT {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-bottom: 0px;
}
.EON__CARD.--TOPFLAT {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-top: 0px;
}
.EON__CARD.--LEFTBORDER {
    border-left-style: solid;
    border-left-width: 3px;
}
.EON__CARD.--BORDER__POSITIVE {
    border-left-color: var(--eon-color-positive-1);
}
.EON__CARD.--BORDER__WARNING {
    border-left-color: var(--eon-color-warning-1);
}
.EON__CARD.--BORDER__DANGER {
    border-left-color: var(--eon-color-danger-1);
}

/* --------------------------------------------------------------------------------------------------------- */

.EON__PICKER {
    --background: var(--eon-bg-more) !important;
    color: var(--eon-contrast-text) !important;
    --border-color: var(--eon-bg-less) !important;
    // make the picker yellow background
    .picker-col {
        --background: var(--eon-color-warning-1) !important;
        --color: var(--eon-contrast-text) !important;
    }
}

.EON__MOLERT {
    backdrop-filter: blur(3px);
    //--background: rgba(0,0,0,.3) !important;
    --background: var(--eon-molert-backdrop) !important;
}

.EON__MOLERT.--ALERT {
    height: 100%;

    .EON__CARD {
        text-align: center;
        margin-left: 30px;
        margin-right: 30px;
        ion-card-title {
            font-size: 30px;
            line-height: 30px;
        }
    }
}

.EON__MOLERT.--TOAST.--TOP {
    //--background: rgba(0,0,0,.7) !important;
    --background: var(--eon-molert-backdrop) !important;
    height: 184px;
    top: 0px;

    .BACKDROP {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;        
    }    
    
    .EON__CARD {
        --background: none;
        box-shadow: none;
        text-align: center;
        margin: 0px;
    }
}
.EON__MOLERT.--TOAST.--TOP.--RADIO {
    //backdrop-filter: none;
    --background: var(--eon-molert-backdrop-more) !important;
    height: 34px;
    margin-left: 150px;
    top: 100px;
    right: 0px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.EON__MOLERT.--TOAST.--TOP.--MUSIC {
    --background: var(--eon-molert-backdrop-more) !important;
    height: 105px;
}


ion-range.EON__RANGE {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    --height: 5px !important;
    --knob-size: 10px !important;
    --knob-background: var(--eon-color-positive-1)
}

ion-progress-bar.EON__PROGRESS {
    --background: var(--eon-color-danger-1);
	--progress-background: var(--eon-color-warning-2);
    height: 10px;
    border-radius: 3px;
}
ion-progress-bar.EON__PROGRESS::part(track) {
    background-color: var(--eon-bg-less);
}

.EON__HEADER__ICON {
    color: var(--eon-contrast-text) !important;
    font-size: 20px;
    font-weight: 600;
}

ion-accordion-group.EON__ACCORDION {
    ion-accordion {
        padding: 0px;
        margin: 0px;
    }
    ion-accordion::part(content) {
        padding: 0px;
        margin: 0px;
    }
}

.EON__SECTION.--MORE {
    background-color: var(--eon-bg-more);
    .EON__ITEMBUTTON {
        --background: var(--eon-bg-more);
    }
    ion-accordion::part(content) {
        background-color: var(--eon-bg-more);
        color: var(--eon-contrast-text);
    }
}
.EON__SECTION.--LESS {
    background-color: var(--eon-bg-less);
}