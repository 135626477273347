// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  //background-image: linear-gradient(to right bottom, #90bf8e, #83b487, #76a97f, #699f78, #5d9471, #588f6e, #528b6a, #4d8667, #4d8667, #4d8667, #4d8667, #4d8667);
  //--ion-color-primary: linear-gradient(to right bottom, #88aba5, #678d8d, #487175, #2a555e, #0a3a48);
  --ion-color-primary: var(--eon-color-positive-1);
  //--ion-color-primary: linear-gradient(to right bottom, #2a7b6f, #358579, #408e82, #4a988c, #54a296, #59a79a, #5dab9f, #518070, #518070, #518070, #518070, #518070);
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: var(--eon-contrast-text);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--eon-color-positive-2);
  --ion-color-primary-tint: var(--eon-color-positive-3);

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #101010;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #101010;
  --ion-color-tertiary-tint: #101010;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #AD644D;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #9f5c47;
  --ion-color-danger-tint: #c18674;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #fff;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

	--ion-color-tabs: #868E8C;
	--ion-color-tabs-rgb: 134,142,140;
	--ion-color-tabs-contrast: #212423;
	--ion-color-tabs-contrast-rgb: 33,36,35;
	--ion-color-tabs-shade: #565c5a;
	--ion-color-tabs-tint: #9aa19e;

	.ion-color-tabs {
		--ion-color-base: var(--avs-tabs-base);
		--ion-color-base-rgb: 134,142,140;
		--ion-color-contrast: #212423;
		--ion-color-contrast-rgb: 33,36,35;
		--ion-color-shade: #e0e0e0;
		--ion-color-tint: #f5f5f5;		
	}
}

.ion-color-interaction {
  --ion-color-base: linear-gradient(to right bottom, #f5abc9, #c595bf, #9381ad, #636d94, #385875, #274e66, #184457, #0a3a48, #0a3a48, #0a3a48, #0a3a48, #0a3a48);
  --ion-color-base-rgb: 105,187,123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #F5ABC9;
  --ion-color-tint: #F5ABC9;
}

:root {
	--avs-tabs-base: var(--eon-bg);	
}