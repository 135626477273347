// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'eon-neuomoprh.scss';
@import 'eon-ionic.scss';

.SPLITBLANK {
    background-color: #000000;
    height: 100%;
    //width: calc(100% - 370px) !important;
    max-width: none !important;

    //background-image: url("https://avoidsociety.app/cover/fullscreen/pa10.jpg");
    //background-size: cover;
    //background-position: bottom;
    background-color: var(--eon-bg-more);
}

.SPLITLOGO {
    width: 50%;
    margin: auto;
}

.SPLITOUTLET {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 370px;
    float: left;
    left: 0px;
}

.SPLITOUTLET__FULL {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 100%;
    float: left;
    left: 0px;
}

.EON__MENU {
    ion-content {
        --background: var(--eon-bg);
    }
}

ion-menu.EON__MENU__EDITOR {
    --width: 700px !important;
    --min-width: 700px !important;
    --max-width: 700px !important;
}

body {
    background-color: var(--eon-bg);
    --background: var(--eon-bg);
}

.header-title {
    font-size: 30px;
    font-weight: 600;
    margin-left: 5px;
    letter-spacing: 0px;
    text-transform: uppercase;    
}

.global-page-heading {
    margin-left: 20px;
    font-size: 35px;
}

.global-noncontent-card {
    background-color: white;
    box-shadow: none;
}

.global-avatar-large {
    width: 50px;
    height: 50px;
}
.global-avatar-image-large {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-size: cover;
}
.global-avatar-small {
    position: absolute;
    left: 45px;
    top: 45px;
    height: 20px;
    width: 20px;
}
.global-avatar-image-small {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-size: cover;
    border-style: solid;
    border-color: white;
    border-width: 2px; 
}

.global-echo-description {
    font-size: 12px;
    color: grey;
    margin-top: 10px;
    margin-left: 15px;
    text-align: left !important;
    width: 100%;
}

.global-echo-singleton-large-card {
    height: 200px;
    width: 100%;
}

.global-echo-multiple-card {
    margin: 0px;
    //margin-right: 0px;
    border-radius: 15px;
    width: 75px;
    height: 75px;
    display: inline-block; 
}

.global-echo-card {
    margin: 0px;
    padding: 0px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    background-position: center;
    background-size: cover;
}

.global-header-image {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
}

.global-infobox {
    background-color: white;
    border-style: none;
    border-radius: 10px;
    margin: 15px;
    padding: 15px;
    font-size: 12px;
}

.global-content-card {
    margin: 0px;
    padding: 0px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    background-position: center;
    background-size: cover;    
}

.global-content-card-large {
    height: 200px;
    width: 100%;
}

.global-content-card-small {
    height: 100px;
    width: 100%;
}

.global-content-card-mini {
    height: 50px;
    width: 100%;
    margin-bottom: 5px;
}

.global-content-card-right {
    margin-left: 0px;
    margin-right: 15px;
    position: relative;
    left: -15px;
}

.global-clean {
    margin: 0px;
    padding: 0px;
}

.global-header {
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    height: 200px;
    margin-left: 30px;
    margin-top: 50px;
}

.global-title {
    display: inline-block;
    margin-top: 50px;
    font-size: 50px;
    font-weight: 600;
    font-variant: small-caps;
    //background: rgba(245, 246, 248, 0.5);
    border-radius: 5px;
    padding-right: 5px;
    padding-left: 5px;
    text-shadow: 2px 2px 20px var(--eon-bg);
}

.eon-card {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
}

.eon-card-text {
    font-size: 14px;
    color: #797979;
    line-height: 20px;
    padding: 20px;
}

.eon-card-text-heading {
    font-variant: small-caps;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}

.eon-card-header {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-left: 25px;
    margin-right: 15px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 0px;
}

.eon-ion-list-plain {
    margin: 0px;
    padding: 0px;
}

.eon-quote {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 18px;
    font-weight: 200;
    font-style: italic;
    color: #797979;
}

.eon__popover {
    //--background: rgba(0,0,0,.3);
    backdrop-filter: blur(3px);
    --min-width: 95%;
}

.EON__TOAST {
    --background: rgba(0,0,0,.7);
    //backdrop-filter: blur(3px);
    --button-color: white;
    --color: white;
}
.EON__TOAST__MUSIC {
    --background: rgba(0,0,0,.7);
    --button-color: var(eon-color-danger-1);
    --color: white;
    font-size: 18px;
    font-variant: small-caps;
}

.EON__TOAST__reward {
    --background: var(--eon-color-warning-1);
    //backdrop-filter: blur(3px);
    --button-color: black;
    --color: black;
    font-size: 24px;
    font-variant: small-caps;
}

.eon__alert {
    --background: rgba(0,0,0,.3);
    backdrop-filter: blur(3px);
    --ion-text-color: white;
}
.eon__alert .alert-wrapper {
    background-color: var(--eon-card-bg);
    border-radius: 10px;
    box-shadow: var(--eon-card-shadow);
    margin: 20px;
    padding: 0px;
}
.eon__alert .alert-title {
    font-variant: small-caps;
    text-align: left;
    font-size: 10px;
    color: var(--eon-contrast-text-less);
}
.eon__alert .alert-sub-title {
    font-variant: small-caps;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--eon-contrast-text);
    font-size: 30px;
}
.eon__alert .alert-message {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 21px;
    hyphens: auto;
    white-space: initial;
    color: var(--eon-contrast-text);
}
.eon__alert .alert-input {
    color: var(--eon-contrast-text);
    background-color: var(--eon-card-bg);
    padding-left: 5px;
}
.eon__alert .alert-button {
    color: var(--eon-contrast-text);
    border-width: 1px;
    border-color: var(--eon-lasercut);
}

/* ------------------------------------------------------------------------------------------ */

.eon__alert__warning {
    --background: rgba(0,0,0,.3);
    backdrop-filter: blur(3px);
    --ion-text-color: white;
}
.eon__alert__warning .alert-wrapper {
    background-color: var(--eon-color-warning-1);
    border-radius: 10px;
    box-shadow: var(--eon-card-shadow);
    margin: 20px;
    padding: 0px;
}
.eon__alert__warning .alert-title {
    font-variant: small-caps;
    text-align: left;
    font-size: 10px;
    color: black;
}
.eon__alert__warning .alert-sub-title {
    font-variant: small-caps;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
    color: black;
    font-size: 30px;
}
.eon__alert__warning .alert-message {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 21px;
    hyphens: auto;
    white-space: initial;
    color: black;
}
.eon__alert__warning .alert-input {
    color: black;
    background-color: var(--eon-color-warning-1);
    padding-left: 5px;
}
.eon__alert__warning .alert-button {
    color: black;
    border-width: 1px;
    border-color: var(--eon-lasercut);
}

.eon__alert__input {
}

/* ------------------------------------------------------------------------------------------ */

.eon__actionSheet {
    --background: rgba(0,0,0,.9);
}

.eon__actionSheet .action-sheet-group {
    max-height: 70%;
    overflow: scroll;
    background-color: #101010;
}

.eon__actionSheet .action-sheet-title {
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #2F4858;
    color: white;
}

.eon__actionSheet .action-sheet-button {
    height: 0px;
    text-align: left;
    --button-background: #305361;
}

.eon__actionSheet .action-sheet-cancel {
    //background-color: #101010;
    background-color: #AD644D !important;
    --button-background-selected: #c0bc84 !important;
    color: white;
}

.eon__actionSheet .action-sheet-button-inner {
    padding: 0px;
    justify-content: left !important;
    font-size: 12px;
}
.eon__actionSheet .action-sheet-button-inner .action-sheet-icon {
    font-size: 12px;
}

.eon__actionSheet .eon__actionSheet__button1 {
    --button-background: #305361;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button2 {
    --button-background: #325F68;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button3 {
    --button-background: #386A6C;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button4 {
    --button-background: #43756F;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button5 {
    --button-background: #518070;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button6 {
    --button-background: #5d8973;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button7 {
    --button-background: #6b9375;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button8 {
    --button-background: #799b77;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button9 {
    --button-background: #8aa47a;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button10 {
    --button-background: #9bac7c;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button11 {
    --button-background: #adb480;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button12 {
    --button-background: #c0bc84;
    color: white;
}
.eon__actionSheet .eon__actionSheet__button13 {
    --button-background: #AD644D;
    color: white;
}

.eon__actionSheet.--B {
    .action-sheet-title {
        background-color: var(--eon-color-positive-2);
        color: white;
    }
    .action-sheet-button-inner {
        font-size: 14px;
        line-height: 14px;
    }
    .eon__actionSheet__button_1 {
        --button-background: var(--eon-color-positive-1);
        color: white;
    }
}